import {
  copyToClipboard,
  formatNumber,
  truncateString,
} from "../../../helpers/helpers";

const TableLimitOrders = () => {
  return (
    <table className="detail-trades-row">
      <tbody className="detail-trades-row">
        <tr
          style={{ display: "grid" }}
          className="row-my-holding detail-trades-title title font-serifBold max-xl:grid-cols-[2fr_3fr_2fr_2fr_2fr_1fr_0.5fr] grid-cols-[2fr_2fr_2fr_2fr_2fr_2fr_1fr]"
        >
          <th>
            <span>Token</span>
          </th>
          <th>
            <span>Initials</span>
          </th>
          <th>
            <span>Order price</span>
          </th>
          <th>
            <span>Market price</span>
          </th>
          <th>
            <span>Total DEXC</span>
          </th>
          <th className="text-nowrap">
            <span>Total SOL</span>
          </th>
          <th>
            <span></span>
          </th>
        </tr>
        <RowLimitOrder
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          orderPrice="about 1 hour ago"
          price={0.0123123}
          totalDexc={formatNumber(20000000)}
          totalSol={4}
        />
        <RowLimitOrder
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          orderPrice="about 1 hour ago"
          price={0.0123123}
          totalDexc={formatNumber(20000000)}
          totalSol={4}
        />
        <RowLimitOrder
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          orderPrice="about 1 hour ago"
          price={0.0123123}
          totalDexc={formatNumber(20000000)}
          totalSol={4}
        />
        <RowLimitOrder
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          orderPrice="about 1 hour ago"
          price={0.0123123}
          totalDexc={formatNumber(20000000)}
          totalSol={4}
        />
        <RowLimitOrder
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          orderPrice="about 1 hour ago"
          price={0.0123123}
          totalDexc={formatNumber(20000000)}
          totalSol={4}
        />
        <RowLimitOrder
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          orderPrice="about 1 hour ago"
          price={0.0123123}
          totalDexc={formatNumber(20000000)}
          totalSol={4}
        />
      </tbody>
    </table>
  );
};

const RowLimitOrder = ({
  image,
  name,
  orderPrice,
  initial,
  price,
  totalDexc,
  totalSol,
}: {
  image: string;
  name: string;
  orderPrice: string;
  initial: string;
  price: number;
  totalDexc: string;
  totalSol: number;
}) => {
  return (
    <tr
      style={{ display: "grid" }}
      className={`row-my-holding detail-trades-title row-thread grid max-xl:grid-cols-[2fr_3fr_2fr_2fr_2fr_1fr_0.5fr] grid-cols-[2fr_2fr_2fr_2fr_2fr_2fr_1fr] text-black`}
    >
      <th
        style={{ wordBreak: "break-all" }}
        className="flex gap-2 items-center"
      >
        <img src={image} width={20} height={20} alt="chad" />

        <p className="font-serifBold text-chad-Heliotrope">{name}</p>
      </th>

      <th
        style={{ wordBreak: "break-all" }}
        className="flex gap-2 items-center break-words"
      >
        <p>{truncateString(initial, 12, 5)}</p>
        <img
          style={{
            transform: "translateY(-2px)",
          }}
          width={16}
          height={16}
          src="/images/modal_profile_address_copy.png"
          onClick={() => {
            copyToClipboard(initial);
          }}
          className="cursor-pointer"
          alt="copy address"
        />
      </th>
      <th style={{ wordBreak: "break-all" }}>
        <span>{orderPrice}</span>
      </th>
      <th style={{ wordBreak: "break-all" }}>
        <span>{price}</span>
      </th>
      <th style={{ wordBreak: "break-all" }}>
        <span className="font-serifBold">{totalDexc}</span>
      </th>
      <th>
        <span className="font-serifBold">{totalSol}</span>
      </th>
      <th>
        <span className="text-chad-Heliotrope">Cancel</span>
      </th>
    </tr>
  );
};

export default TableLimitOrders;
