import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import ModalReply from "../ModalReply/ModalReply";
import { ReactComponent as Love } from "../../card/svgs/love.svg";
import { ReactComponent as Loved } from "../../card/svgs/loved.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import { END_POINT } from "../../../constants";

if (Cookies.get("token")) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
    "token"
  )}`;
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
}

function TableThread() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [comments, setComments] = useState([]);
  const [mention, setMention] = useState([]);
  const { poolId } = useParams();

  useEffect(() => {
    if (poolId) {
      axios
        .get(END_POINT + "/comments/" + poolId)
        .then((response) => {
          console.log(response);
          setComments(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [modalIsOpen]);

  async function openModal(mention) {
    setIsOpen(true);
    setMention(mention);
  }

  return (
    <>
      <ModalReply
        modalIsOpen={modalIsOpen}
        mention={mention}
        setIsOpen={setIsOpen}
      />
      <div className="">
        {comments.map((comment) => (
          <RowThread
            openModal={openModal}
            image={comment.avatar}
            name={comment.username}
            time={comment.created_date}
            love={comment.like_count.length}
            loved={comment.isLiked}
            id={comment.id}
            mess={comment.content}
            key={comment.id}
          />
        ))}
      </div>
      <p
        onClick={() => {
          openModal(null);
        }}
        className="text-chad-Heliotrope cursor-pointer"
      >
        Reply All
      </p>
    </>
  );
}

const RowThread = ({
  image,
  name,
  time,
  love,
  loved = false,
  id,
  mess,
  openModal,
}: {
  image: string;
  name: string;
  time: string;
  love: string | number;
  loved?: boolean;
  id: string;
  mess?: string;
  openModal: (id: any) => void;
}) => {
  const [stateLoved, setstateLoved] = useState(loved);
  const [stateLoveCount, setstateLoveCount] = useState(love);

  async function like() {
    try {
      const result: any = await axios.post(END_POINT + "/comments/like", {
        commentId: id,
      });
      console.log(result);
      if (result && result?.data?.status === "success") {
        setstateLoved(result.data.isLiked);
        setstateLoveCount(result.data.count);
      }
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <div className="text-black p-2 flex flex-row gap-2 row-thread">
      <img className="w-4 h-4" src={image} alt={"avatar"} />

      <div className="flex-1 flex flex-col gap-2">
        <div className="flex flex-row">
          <h3
            className="flex-1 text-chad-Heliotrope text-sm font-bold"
            style={{
              fontFamily: "MS Sans Serif Bold",
            }}
          >
            {name}
          </h3>
          <div className="flex gap-6">
            <p>{time}</p>
            <p className="flex w-[50px] justify-center">
              <span className="cursor-pointer" onClick={like}>
                {stateLoved ? <Loved /> : <Love />}
              </span>
              <span className="ml-2">{stateLoveCount}</span>
            </p>
            <p>#{id}</p>
            {Cookies.get("token") && (
              <p
                onClick={() => {
                  openModal(id);
                }}
                className="text-chad-Heliotrope cursor-pointer"
              >
                Reply
              </p>
            )}
          </div>
        </div>
        <div className="break-all">{mess}</div>
      </div>
    </div>
  );
};

export default TableThread;
