import React from "react";

type Props = {
  children?: React.ReactNode;
};

export default function ButtonYellow({ children }: Props) {
  return (
    <div
      style={{
        backgroundImage: `url('/images/profile/bg-btn-yellow.png')`,
        backgroundPosition: "center bottom",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%",
        height: "40px",
        width: "163px",
        minWidth: "163px",
        fontFamily: "ThaleahFat",
        color: "#000",
        fontSize: "20px",
        cursor: "pointer",
      }}
      className="flex items-center justify-center"
    >
      {children}
    </div>
  );
}
