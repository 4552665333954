import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { WalletProvider, ConnectionProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import type { Adapter } from '@solana/wallet-adapter-base';
import type { SolanaSignInInput, SolanaSignInOutput } from '@solana/wallet-standard-features';
import { END_POINT } from './constants';
import axios from 'axios';
import { AutoConnectProvider } from './components';
import Cookies from 'js-cookie';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { Toaster } from "react-hot-toast";

import { createSignInData } from './utils/createSignInData';

import { useCookies } from "react-cookie";

import Home from "./pages/home";
import Detail from "./pages/detail";

import "./scss/global.scss";
import "./scss/index.scss";
import "./scss/wallet.scss";
import "./scss/antd.scss";
import Profile from "./pages/Profile";
import UserProfile from "./pages/UserProfile";
import MainLayout from "./layout/MainLayout";
import { stringify } from 'querystring';
import TestEvent from './pages/TestEvent';


require("@solana/wallet-adapter-react-ui/styles.css");

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
          index: true,
          element: <Home />,
      },
      {
        path: "/detail/:poolId",
        element: <Detail />,
      },
      {
        path: "/profile",
        element: <Profile />,
      },
      {
        path: "/user-profile",
        element: <UserProfile />,
      },

      {
        path: "/events",
        element: <TestEvent />,
      },
    ]
  }
]);


// =============================================================================
// Main Component
// =============================================================================
const App = () => {

  const [cookies, setCookies] = useCookies();
  
  const network = WalletAdapterNetwork.Mainnet;

  const endpoint = `https://convincing-white-theorem.solana-devnet.quiknode.pro/2148aa6f1a25b5c071a0d74f35d0f077f97f5f02/`;

  const wallets = useMemo(
    () => [],
    [network]
  );

  const autoSignIn = useCallback(async (adapter: Adapter) => {
    if (!('signIn' in adapter)) return true;

    // if(typeof cookies.SolanaSignedIn !== "undefined"){
    if(typeof Cookies.get('token') !== "undefined"){
      return true;
    }

    // Fetch the signInInput from the backend
    const input: SolanaSignInInput = await createSignInData();
    console.log(`input`, input)

    // Send the signInInput to the wallet and trigger a sign-in request
    const output = await adapter.signIn(input);
    const address = output.account.address;
    console.log(`address`, address)
    console.log(`output`, output)

    const verifyResponse = await axios.post(END_POINT + '/users/verify', {input, output, address});

    console.log(verifyResponse.data);

    setCookies('SolanaSignedIn', verifyResponse.data.token);
    Cookies.set('token', verifyResponse.data.token);

    console.log('autoSignIn is success');
    
    return false;
  }, []);

  return (
    <AutoConnectProvider>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={autoSignIn}>
          <WalletModalProvider>
            <RouterProvider router={router} />
            <Toaster
              position="top-center"
              toastOptions={{
                duration: 2500,
              }}
            />
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </AutoConnectProvider>
  );
};

export default App;
