export const dummy = [
    {
        timestamp: 1716392400000,
        o: '0.000003682491207895718',
        c: '0.00000451132341068518',
        h: '0.000005132515706443143',
        l: '0.000003682491207895718',
        volume: 1044.656270668651
    },
    {
        timestamp: 1716392460000,
        o: '0.000004503833759818087',
        c: '0.000004706354628569288',
        h: '0.000004706354628569288',
        l: '0.000004503833759818087',
        volume: 180.728372
    },
    {
        timestamp: 1716392580000,
        o: '0.000004969110497372214',
        c: '0.000006555341665238012',
        h: '0.00000717561464948281',
        l: '0.000004969110497372214',
        volume: 3457.750612332829
    },
    {
        timestamp: 1716392640000,
        o: '0.00000646755155331022',
        c: '0.000008806925406007852',
        h: '0.000008991110801147478',
        l: '0.000006382307882153317',
        volume: 4050.004940469431
    },
    {
        timestamp: 1716392700000,
        o: '0.000008775869262165051',
        c: '0.00001264505567176023',
        h: '0.00001266655712573693',
        l: '0.000008737558137539778',
        volume: 3562.66818745728
    },
    {
        timestamp: 1716392760000,
        o: '0.00001275267277415658',
        c: '0.00001412530866125263',
        h: '0.00001643313822082276',
        l: '0.00001123570732418185',
        volume: 5887.76557034397
    },
    {
        timestamp: 1716392820000,
        o: '0.000013858986307735',
        c: '0.00001339895461493227',
        h: '0.00001635680535433157',
        l: '0.00001309406998699748',
        volume: 5983.78667940225
    },
    {
        timestamp: 1716392880000,
        o: '0.00001324338864950207',
        c: '0.00001579518248087217',
        h: '0.00001585049148467522',
        l: '0.00001268903633632651',
        volume: 7209.9199692855
    },
    {
        timestamp: 1716392940000,
        o: '0.00001589752735911239',
        c: '0.00002044745252160337',
        h: '0.0000205315186064152',
        l: '0.0000152802428117014',
        volume: 5788.137191565334
    },
    {
        timestamp: 1716393000000,
        o: '0.0000206624721516948',
        c: '0.00002859476947560463',
        h: '0.00002902400220018483',
        l: '0.00002035132659692792',
        volume: 5473.334352314376
    },
    {
        timestamp: 1716393060000,
        o: '0.00002874225109954311',
        c: '0.0000261044758791473',
        h: '0.00003197514421953944',
        l: '0.0000261044758791473',
        volume: 7731.783764408248
    },
    {
        timestamp: 1716393120000,
        o: '0.00002573661209390697',
        c: '0.00001962676940788037',
        h: '0.00003825401970291878',
        l: '0.00001877483036793058',
        volume: 12468.91312513611
    },
    {
        timestamp: 1716393180000,
        o: '0.00001993782111220684',
        c: '0.00001903061260573809',
        h: '0.0000213262586325417',
        l: '0.0000172762039223045',
        volume: 6454.297798509148
    },
    {
        timestamp: 1716393240000,
        o: '0.00001893188723133557',
        c: '0.0000210393881262905',
        h: '0.00002107397130306011',
        l: '0.00001893188723133557',
        volume: 1739.549649999689
    },
    {
        timestamp: 1716393300000,
        o: '0.0000215857920779086',
        c: '0.00003203784627861868',
        h: '0.00003251119776761628',
        l: '0.0000215857920779086',
        volume: 10271.22375721059
    },
    {
        timestamp: 1716393360000,
        o: '0.00003266694455312833',
        c: '0.00003965395914675464',
        h: '0.00004271295885962959',
        l: '0.00003266694455312833',
        volume: 9362.900758387214
    },
    {
        timestamp: 1716393420000,
        o: '0.00003908393756490733',
        c: '0.00004554436798302824',
        h: '0.00004554436798302824',
        l: '0.00003861022084323428',
        volume: 8582.793402652645
    },
    {
        timestamp: 1716393480000,
        o: '0.00004541734210878453',
        c: '0.00004817955999007337',
        h: '0.0000512556769368398',
        l: '0.00004439422815441089',
        volume: 5299.081657292707
    },
    {
        timestamp: 1716393540000,
        o: '0.00004833322832062875',
        c: '0.00005456119973661772',
        h: '0.00006029048729776287',
        l: '0.00004833322832062875',
        volume: 8374.713922155679
    },
    {
        timestamp: 1716393600000,
        o: '0.00005529168631929679',
        c: '0.00007071659308418168',
        h: '0.00007133318953386656',
        l: '0.00005529168631929679',
        volume: 6941.31592395912
    },
    {
        timestamp: 1716393660000,
        o: '0.00007114572467291934',
        c: '0.00005491250613546205',
        h: '0.0000715572379699529',
        l: '0.00005491250613546205',
        volume: 10730.73341631412
    },
    {
        timestamp: 1716393720000,
        o: '0.00005504798861259468',
        c: '0.00003974688937875792',
        h: '0.00005504798861259468',
        l: '0.00003558118772685989',
        volume: 14348.76159110339
    },
    {
        timestamp: 1716393780000,
        o: '0.00004043279504135764',
        c: '0.00003485359915847384',
        h: '0.00004844366201121548',
        l: '0.0000334455723171512',
        volume: 12244.82356863788
    },
    {
        timestamp: 1716393840000,
        o: '0.00003504936076359611',
        c: '0.00003687477468444212',
        h: '0.00004253342508764782',
        l: '0.00003221483198324602',
        volume: 9787.838015991649
    },
    {
        timestamp: 1716393900000,
        o: '0.00003720829964078172',
        c: '0.00002964474778053934',
        h: '0.00003738640646774692',
        l: '0.0000284373930490652',
        volume: 13351.66623689192
    },
    {
        timestamp: 1716393960000,
        o: '0.00002977898775413372',
        c: '0.00004217957902892982',
        h: '0.00004217957902892982',
        l: '0.0000295887804879722',
        volume: 6480.881526596074
    },
    {
        timestamp: 1716394020000,
        o: '0.00004328910492554213',
        c: '0.0000392333075240234',
        h: '0.00004952055620361196',
        l: '0.00003704300746332803',
        volume: 18930.03297895305
    },
    {
        timestamp: 1716394080000,
        o: '0.0000383659446982122',
        c: '0.00006232000636602561',
        h: '0.00006513402312445378',
        l: '0.00003659640779775376',
        volume: 13307.12746467124
    },
    {
        timestamp: 1716394140000,
        o: '0.00006158122256740047',
        c: '0.00008134194247307759',
        h: '0.00008361833817864718',
        l: '0.0000505359690241329',
        volume: 12765.21171537017
    },
    {
        timestamp: 1716394200000,
        o: '0.00008056629718039688',
        c: '0.0001033018992301515',
        h: '0.0001033018992301515',
        l: '0.00007096660484033196',
        volume: 11887.6516370833
    },
    {
        timestamp: 1716394260000,
        o: '0.0001025161496914041',
        c: '0.0001142871354346459',
        h: '0.0001256645173294844',
        l: '0.0001025161496914041',
        volume: 11231.01598564325
    },
    {
        timestamp: 1716394320000,
        o: '0.0001115584166636811',
        c: '0.0001247659900270172',
        h: '0.0001284899526564006',
        l: '0.0001086673951217855',
        volume: 9246.152881872
    },
    {
        timestamp: 1716394380000,
        o: '0.0001267232757584862',
        c: '0.0001395433703657117',
        h: '0.0001475372450436287',
        l: '0.0001222945176012931',
        volume: 7892.29791004272
    },
    {
        timestamp: 1716394440000,
        o: '0.0001409037443911364',
        c: '0.0001511064616715184',
        h: '0.0001511064616715184',
        l: '0.0001275961253620163',
        volume: 13431.79930113454
    },
    {
        timestamp: 1716394500000,
        o: '0.0001522210413162941',
        c: '0.0001852815810041104',
        h: '0.0001852815810041104',
        l: '0.0001508822456915477',
        volume: 17736.63539367196
    },
    {
        timestamp: 1716394560000,
        o: '0.0001834020604623319',
        c: '0.0001996821935003176',
        h: '0.0002016889990243149',
        l: '0.0001742162236880396',
        volume: 7598.755732302518
    },
    {
        timestamp: 1716394620000,
        o: '0.000201649844251022',
        c: '0.0002050566456612137',
        h: '0.0002050566456612137',
        l: '0.000201649844251022',
        volume: 974.2561427999999
    },
    {
        timestamp: 1716394740000,
        o: '0.0002071176200673062',
        c: '0.0003003395933981457',
        h: '0.0003003395933981457',
        l: '0.0002071176200673062',
        volume: 9452.388157013886
    },
    {
        timestamp: 1716394800000,
        o: '0.0003025134191416791',
        c: '0.0003026080776122117',
        h: '0.0003026080776122117',
        l: '0.0003025134191416791',
        volume: 40.142415196731
    },
    {
        timestamp: 1716394860000,
        o: '0.0003026565823316488',
        c: '0.0003026565823316488',
        h: '0.0003026565823316488',
        l: '0.0003026565823316488',
        volume: 1.823222768000763
    },
    {
        timestamp: 1716394920000,
        o: '0.0003058898071579243',
        c: '0.0004438934757257739',
        h: '0.000491225772344224',
        l: '0.0003058898071579243',
        volume: 13126.2096707616
    },
    {
        timestamp: 1716394980000,
        o: '0.0004436814689964386',
        c: '0.000531926008526102',
        h: '0.0005705524022409092',
        l: '0.0004436814689964386',
        volume: 24488.51322897738
    },
    {
        timestamp: 1716395040000,
        o: '0.0005349937020866299',
        c: '0.0006157290757579223',
        h: '0.0006227874225026356',
        l: '0.0005254368405037176',
        volume: 12971.84974796412
    }
]
