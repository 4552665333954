import { useEffect, useState } from "react";
import { useAnchorWallet, useConnection } from "@solana/wallet-adapter-react";
import {
  AnchorProvider,
  setProvider,
} from "@project-serum/anchor";

import { fetchToken, getProgram } from "../web3/program";
import { swapbasein, swapbaseinsell, swapbaseout } from "../web3/pump";
import { sleep } from "../helpers/helpers";

import { PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js';
import toast from "react-hot-toast";
import ReactLoading from "react-loading";
import { symbolName } from "typescript";

enum AMOUNT_OPT{
  DEFAULT = 0,
  ONE = 1,
  TWO=2,
  FIVE = 5,
  TEN = 10,
}

export default function TestEvent() {

  const [solBalance, setSolBalance] = useState(0);

  const { connection } = useConnection();
  const wallet = useAnchorWallet();
  const provider = new AnchorProvider(connection, wallet, {});
  setProvider(provider);

    const program = getProgram();

    interface PoplData {
      poolId: string;
      token0Amount: string;
      token0Mint: string;
      token1Amount: string;
      token1Mint: string;
      symbol: string;
      decimals: number;
      image: string;
    }

    const [pool, setPool] = useState<PoplData>();

    useEffect(() => {
        const subscriptionCreatePoolEvent = program.addEventListener(
          "CreatePoolEvent",
          (event) => {
            console.log(`event`, event)
            setPool({
              poolId: event.poolId.toBase58(),
              token0Amount: event.token0Amount.toString(),
              token0Mint: event.token0Mint.toBase58(),
              token1Amount: event.token1Amount.toString(),
              token1Mint: event.token1Mint.toBase58(),
              symbol: '',
              decimals: 0,
              image: ''
            })

            // DB store poolData
          }
        );

        const subscriptionSwapEvent = program.addEventListener(
          "SwapEvent",
          (event) => {
            console.log(`swap`, event)
            // DB store poolData
          }
        );

        csGetSOLBalance()
    
    }, [wallet]);

    useEffect(() => {
      
      updatePoolData();
      // updatePoolData(`7pLShj6pojQuzrB8tmcFMQs6mu2GK1ruAxouKHksjyRs`);
    }, [pool]);


    const csGetSOLBalance = async () => {
      if (!wallet) return;
      let balance = await connection.getBalance(wallet.publicKey);
      setSolBalance(balance / LAMPORTS_PER_SOL);
    };
    

    const updatePoolData = async () => {
      if(!pool) return
      const mint = pool.token1Mint;

      console.log(`fetching... 20s`)
      await sleep(20000);

      let poolData = {}; // Lấy từ DB lên
      let tokenData = await fetchToken(mint);
      // console.log(`tokenData`, tokenData)
      if(tokenData){
        const metadata = {
          name: tokenData.metadata.name,
          symbol: tokenData.metadata.symbol,
          decimals: tokenData.mint.decimals
        }

        let uriContent = await fetch(tokenData.metadata.uri);
        let jsonContent = await uriContent.json();
        // console.log(`jsonContent`, jsonContent)

        let extentData = {
          description: jsonContent.description,
          image: jsonContent.image,
          website: jsonContent.extensions.website,
          twitter: jsonContent.extensions.twitter,
          telegram: jsonContent.extensions.telegram
        }

        poolData = {...pool, ...metadata, ...extentData};
      }
      console.log(`poolData`, poolData);
      // DB update poolData
    }


    const [selectedBtnId, setSelectedBtnId] = useState();
    const [btnLoading, setBtnLoading] = useState(false);
    const addSpinner = (btnId) => {
      setBtnLoading(true);
      setSelectedBtnId(btnId);
    };
    const removeSpinner = (btnId) => {
      setBtnLoading(false);
      setSelectedBtnId(null);
    };

    const poolDemo: PoplData = {
      poolId: `BgCHbvCNuJdwAieiF22wHufiaYyba9VeVewVrFskY8sg`,
      token0Amount: `30000000000`,
      token0Mint: `So11111111111111111111111111111111111111112`,
      token1Amount: `1000000000000000`,
      token1Mint: `6tF5V9ANMCmWFWxWih3T15xMzsSQteFGvHgmTSR3VXRF`,
      symbol: `SW1CHAD`,
      decimals: 6,
      image: `https://quicknode.quicknode-ipfs.com/ipfs/QmaHL4dBzgPx92L9xgQ7849kXwk2RAdnN95A8Q3n2BS8vg`
    };

    let swapSOL = {symbol:'SOL', img:'/images/Solana-Logo.png'};
    let swapToken = {symbol:poolDemo.symbol, img:poolDemo.image};

    const [tokenIn, setTokenIn] = useState(swapSOL);
    const [amountOpt, setAmountOpt] = useState<AMOUNT_OPT>(AMOUNT_OPT.DEFAULT);

    const [inputAmount, setInputAmount] = useState(0);
    const [inputAmountSell, setInputAmountSell] = useState(0);

    const handleBuy = async () => {
      
      const slipage = 1;

      const btnID = "create-swap";
      addSpinner(btnID);

      let created;
      if(tokenIn.symbol == 'SOL'){
        let amountIn = inputAmount;
        let minimumAmountOut = 1;  // calc
        created = await swapbasein(poolDemo, amountIn, minimumAmountOut, slipage, wallet, connection);
      }else{
        let maxAmountIn = 10; // calc
        let amountOut = inputAmount; 
        created = await swapbaseout(poolDemo, maxAmountIn, amountOut, slipage, wallet, connection);
      }

      console.log(`created`, created)
      if (created.status == "success") {
        await sleep(6000);
        removeSpinner(btnID);
        toast.success(created.message);
      } else {
        removeSpinner(btnID);
        toast.error(created.message);
      }
    }

    const handleSell = async () => {
      
      const slipage = 1;

      const btnID = "create-swap2";
      addSpinner(btnID);

      let created;
      let amountIn = inputAmountSell; // token
      let minimumAmountOut = 0.01;  // calc - SOL
      created = await swapbaseinsell(poolDemo, amountIn, minimumAmountOut, slipage, wallet, connection);

      console.log(`created`, created)
      if (created.status == "success") {
        await sleep(6000);
        removeSpinner(btnID);
        toast.success(created.message);
      } else {
        removeSpinner(btnID);
        toast.error(created.message);
      }
    }

    const handleSetTokenIn = async () =>{
      setTokenIn(
        (tokenIn.symbol == 'SOL')? swapToken : swapSOL
      )
    }

    return (
      <>
        <div className="container mx-auto">
          <div className="sidebar-box box-shadow !min-w-[500px]">
            <div className="sidebar-box-content">
              <div className="sidebar-box-des">
                <span onClick={handleSetTokenIn}>Swich to {(tokenIn.symbol == 'SOL') ? swapToken.symbol : swapSOL.symbol}</span>
                <span>Set max slippage</span>
              </div>
              <div className="sidebar-box-input text-base text-black">
                <input
                  style={{
                    backgroundImage: "url('/images/bg_input.png')",
                  }}
                  className="placeholder:text-[#404040] bg-mid-center outline-none bg-size100 bg-no-repeat bg-transparent"
                  type="number"
                  placeholder=""
                  onChange={(e) => setInputAmount(parseFloat(e.target.value))}
                  value={ inputAmount }
                />
                <span>
                  {tokenIn.symbol}
                  <div>
                    <img
                      src={tokenIn.img}
                      alt="crown"
                      className="icon"
                      width={20}
                      height={20}
                    />
                  </div>
                </span>
              </div>

              <div className="sidebar-buy text-black mt-2">
                <span
                  onClick={() => setInputAmount(AMOUNT_OPT.DEFAULT)}
                    className={
                      inputAmount === AMOUNT_OPT.DEFAULT
                        ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
                        : "text cursor-pointer"
                    }
                  >
                    Reset
                  </span>
                  <span
                    onClick={() => setInputAmount(AMOUNT_OPT.ONE)}
                    className={
                      inputAmount === AMOUNT_OPT.ONE
                        ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
                        : "text cursor-pointer"
                    }
                  >
                    1 SOL
                  </span>
                  <span
                    onClick={() => setInputAmount(AMOUNT_OPT.TWO)}
                    className={
                      inputAmount === AMOUNT_OPT.TWO
                        ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
                        : "text cursor-pointer"
                    }
                  >
                    2 SOL
                  </span>
                  <span
                    onClick={() => setInputAmount(AMOUNT_OPT.FIVE) }
                    className={
                      inputAmount === AMOUNT_OPT.FIVE
                        ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
                        : "text cursor-pointer"
                    }
                  >
                    5 SOL
                  </span>
                  <span
                    onClick={() => setInputAmount(AMOUNT_OPT.TEN)}
                    className={
                      inputAmount === AMOUNT_OPT.TEN
                        ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
                        : "text cursor-pointer"
                    }
                  >
                    10 SOL
                  </span>
                </div>


              <div className="flex align-center justify-center gap-2 mt-3">
                <button
                  className="btn-common flex align-center justify-center gap-1"
                  id="create-swap"
                  disabled={ btnLoading && selectedBtnId === "create-swap" ? true : false }
                  onClick={handleBuy}
                >
                  <span>BUY</span>
                  {btnLoading && selectedBtnId === "create-swap" && (
                    <ReactLoading type="bars" height={20} width={20} />
                  )}
                </button>

                {/* <button
                  className="btn-common flex align-center justify-center"
                  id="create-sell"
                  disabled={ btnLoading && selectedBtnId === "create-sell" ? true : false }
                  onClick={handleBuy}
                >
                  <span>SELL</span>
                  {btnLoading && selectedBtnId === "create-sell" && (
                    <ReactLoading type="bars" height={20} width={20} />
                  )}
                </button> */}
              </div>
            </div>
          </div>
        
          <div className="sidebar-box box-shadow !min-w-[500px]">
            <div className="sidebar-box-content">
              <div className="sidebar-box-des">
                <span></span>
                <span>Set max slippage</span>
              </div>
              <div className="sidebar-box-input text-base text-black">
                <input
                  style={{
                    backgroundImage: "url('/images/bg_input.png')",
                  }}
                  className="placeholder:text-[#404040] bg-mid-center outline-none bg-size100 bg-no-repeat bg-transparent"
                  type="number"
                  placeholder=""
                  onChange={(e) => setInputAmountSell(parseFloat(e.target.value))}
                  value={ inputAmountSell }
                />
                <span>
                  {poolDemo.symbol}
                  <div>
                    <img
                      src={poolDemo.image}
                      alt="crown"
                      className="icon"
                      width={20}
                      height={20}
                    />
                  </div>
                </span>
              </div>

              <div className="flex align-center justify-center gap-2 mt-3">
                <button
                  className="btn-common flex align-center justify-center gap-1"
                  id="create-swap2"
                  disabled={ btnLoading && selectedBtnId === "create-swap2" ? true : false }
                  onClick={handleSell}
                >
                  <span>SELL</span>
                  {btnLoading && selectedBtnId === "create-swap2" && (
                    <ReactLoading type="bars" height={20} width={20} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
}
