function TableTrades() {
  return (
    <table className="detail-trades-row">
      <tbody className="detail-trades-row">
        <tr className="detail-trades-title title font-serifBold">
          <th>
            <span>Account</span>
          </th>
          <th>
            <span>Type</span>
          </th>
          <th>
            <span>Date</span>
          </th>
          <th>
            <span>SOL</span>
          </th>
          <th>
            <span>BBBY</span>
          </th>
          <th>
            <span>Transaction</span>
          </th>
        </tr>

        <RowTrade isBuy />
        <RowTrade isBuy={false} />
        <RowTrade isBuy={false} />
        <RowTrade isBuy />
        <RowTrade isBuy={false} />
        <RowTrade isBuy={false} />
        <RowTrade isBuy />
        <RowTrade isBuy={false} />
        <RowTrade isBuy />
      </tbody>
    </table>
  );
}

const RowTrade = ({ isBuy }: { isBuy: boolean }) => {
  return (
    <tr className={`detail-trades-title row-thread`}>
      <th>
        <span>
          <img src="/images/avatar.png" alt="chad" />
          Hc1WnX
        </span>
      </th>
      <th className={`${isBuy ? "buy" : "sell"}`}>
        <span>Buy</span>
      </th>
      <th>
        <span>1h ago</span>
      </th>
      <th>
        <span>0.2</span>
      </th>
      <th>
        <span>245k</span>
      </th>
      <th>
        <span>Transaction</span>
      </th>
    </tr>
  );
};

export default TableTrades;
