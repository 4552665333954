import { useState } from "react";
import InputTrade from "../InputTrade/InputTrade";
import InputSol from "../InputSol/InputSol";

enum MULTIPLES {
  DEFAULT = 1,
  TWO = 2,
  FIVE = 5,
  TEN = 10,
}

function BuyTrade() {
  const [multiple, setMultiple] = useState<MULTIPLES>(MULTIPLES.DEFAULT);

  const [enable, setEnable] = useState<boolean>(false);

  return (
    <>
      <div>
        <p className="mb-2">Set max slippage</p>
        <div className="flex input-options justify-between max-sm:grid max-sm:grid-cols-1 max-lg:flex-row gap-2 flex-col xl:grid xl:grid-cols-1 2xl:flex 2xl:flex-row">
          <div className="relative">
            <span
              className="absolute top-[50%]"
              style={{
                transform: "translate(10px,-50%)",
              }}
            >
              %
            </span>
            <input
              style={{
                backgroundImage: "url('/images/detail/bg-input-short.png')",
                padding: "8px 6px 8px 36px",
              }}
              className="placeholder:text-[#404040] h-[45px] bg-mid-center outline-none bg-size100 bg-no-repeat bg-transparent"
              type="number"
              value={1}
              placeholder="1"
            />
          </div>
        </div>
      </div>
      {/* <div>
        <p className="mb-2">Auto TP/SL</p>
        <div className="flex input-options justify-between max-sm:grid max-sm:grid-cols-2 max-lg:flex-row gap-2 flex-col xl:grid xl:grid-cols-2 2xl:flex 2xl:flex-row">
          <InputTrade fieldName="TP" />
          <InputTrade fieldName="SL" />
          <InputTrade fieldName="ROI" />
        </div>
      </div>
      <div>
        <div className="flex justify-between items-center">
          <p className="mb-2">Smart-Mev protection</p>
          <p className="flex gap-1 items-center">
            <span>Enable </span>{" "}
            <span
              onClick={() => {
                setEnable(() => !enable);
              }}
              className={`checkbox ${
                enable ? "checkbox-checked" : ""
              } cursor-pointer`}
            ></span>
          </p>
        </div>
        <InputSol placeholder="Bribery Amount" />
      </div>
      <div>
        <p className="mb-2">Priority Fee</p>
        <InputSol placeholder="0.05" />
        <div className="sidebar-buy text-black mt-2">
          <span
            onClick={() => setMultiple(MULTIPLES.DEFAULT)}
            className={
              multiple === MULTIPLES.DEFAULT
                ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
                : "text cursor-pointer"
            }
          >
            Default
          </span>
          <span
            onClick={() => setMultiple(MULTIPLES.TWO)}
            className={
              multiple === MULTIPLES.TWO
                ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
                : "text cursor-pointer"
            }
          >
            2x
          </span>
          <span
            onClick={() => setMultiple(MULTIPLES.FIVE)}
            className={
              multiple === MULTIPLES.FIVE
                ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
                : "text cursor-pointer"
            }
          >
            5x
          </span>
          <span
            onClick={() => setMultiple(MULTIPLES.TEN)}
            className={
              multiple === MULTIPLES.TEN
                ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
                : "text cursor-pointer"
            }
          >
            10x
          </span>
        </div>
      </div> */}
    </>
  );
}

export default BuyTrade;
