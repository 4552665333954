import {default as IDL} from "./pump.json";
import { PublicKey } from '@solana/web3.js';

import { Program } from "@project-serum/anchor";

import { fetchDigitalAsset } from '@metaplex-foundation/mpl-token-metadata';
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults';
import { mplCandyMachine } from "@metaplex-foundation/mpl-candy-machine";


const umi = createUmi(process.env.REACT_APP_RPC_END_POINT).use(mplCandyMachine())



export function getProgramId(){
    return new PublicKey(process.env.REACT_APP_PROGRAM_ID);
};
export function getProgram(){
    const programId = new PublicKey(process.env.REACT_APP_PROGRAM_ID);
    return new Program(IDL, programId);
};

export async function quickNodeUpload(file, name) {
    if(!file){ return ""}
    let cid
    let myHeaders = new Headers()
    myHeaders.append("x-api-key", process.env.REACT_APP_QUICKNODE_KEY)
    let formdata = new FormData()
    formdata.append("Body", file)
    formdata.append("Key", Math.random().toString(10).slice(2)+name+".png")
    formdata.append("ContentType", "image/png")
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    }
    try {
        await fetch("https://api.quicknode.com/ipfs/rest/v1/s3/put-object", requestOptions)
        .then(response => response.text())
        .then(
            function(result){
                let data = JSON.parse(result)
                cid = data.pin.cid
            }
        )
        .catch(error => console.log('error', error))
    } catch (error) {
        console.log('error', error)
    }
    return cid
}

export async function quickNodeUploadMetadata(obj, name) {
    if(!obj){ return false}
    obj = new Blob([JSON.stringify(obj)], {type: 'application/json'})
    const metadataFile = `metadata_${name}.json`;
    let cid
    let myHeaders = new Headers()
    myHeaders.append("x-api-key", process.env.REACT_APP_QUICKNODE_KEY)
    let formdata = new FormData()
    formdata.append("Body", obj)
    formdata.append("Key", metadataFile)
    formdata.append("ContentType", "text/plain")
    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
    }
    try {
        await fetch("https://api.quicknode.com/ipfs/rest/v1/s3/put-object", requestOptions)
        .then(response => response.text())
        .then(
            function(result){
                let data = JSON.parse(result)
                cid = data.pin.cid
            }
        )
        .catch(error => console.log('error', error));
    } catch (error) {
        console.log('error', error)
    }
    return cid
}

export function quickNodeGetFile(filename){
    if(filename){
        return `https://quicknode.quicknode-ipfs.com/ipfs/${filename}`
    }else{
        return ``
    }
}

export async function pinataUpload(file, name) {
    if(!file){ return false}
    let cid

    const formData = new FormData()
    formData.append("file", file)
    const metadata = JSON.stringify({
        name: Math.random().toString(10).slice(2)+name+".png"
    })
    formData.append("pinataMetadata", metadata)

    const options = JSON.stringify({
        cidVersion: 0,
    })
    
    formData.append("pinataOptions", options)

    try {
        const res = await fetch(
            "https://api.pinata.cloud/pinning/pinFileToIPFS",
            {
            method: "POST",
            headers: {
                Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
            },
            body: formData,
            }
        )

        const resData = await res.json()
        cid = resData.IpfsHash
    } catch (error) {
        console.log('error', error)
    }
    return cid
}

export function pinataGetFile(filename){
    if(filename){
        return `${process.env.REACT_APP_PINATA_GATEWAY_URL}/ipfs/${filename}`
    }else{
        return ``
    }
}

export async function fetchToken(mint) {
    try {
        const asset = await fetchDigitalAsset(umi, new PublicKey(mint))
        return asset
    } catch (error) {
        console.log(error.message)
        return false
    } 
}

