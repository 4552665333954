import Card from "../Card";
import { ReactComponent as Love } from "../svgs/love.svg";
import { ReactComponent as Loved } from "../svgs/loved.svg";
import { useState, useEffect } from "react";
import { END_POINT } from '../../../constants';
import Cookies from 'js-cookie';
import axios from "axios";
import dayjs from 'dayjs';

if(Cookies.get('token')) {
  // console.log('token', Cookies.get('token'));
  axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
}

type Props = {};

export default function Replies({}: Props) {
  const [replies, setReplies] = useState([]);

  useEffect(() => {
      axios.get(END_POINT + '/users/comments/data')
      .then(response => {
        if(response.status === 200 && response.data.data) {
          setReplies(response.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
      console.log(dayjs('2024-05-25T17:00:00.000Z').format('YYYY-MM-DD HH:mm'));
  }, []);

  

  return (

    <Card header={CustomHeader()}>
      <div className="flex flex-col gap-4 overflow-y-auto h-[50vh]">
      {
        replies.map((item) => {
          const createdDate = dayjs(item.created_date).format('YYYY-MM-DD HH:mm');
          return <Item
            key={item.id}
            image={item.avatar}
            name={item.username}
            time={createdDate}
            love={5}
            id={'#'+item.id}
            mess={item.content}
            commentImg={item.image}
          />
          }
      )}
      </div>
    </Card>
  );
}

const CustomHeader = () => (
  <div className="flex flex-row gap-1 items-center">
    <h1 className="uppercase">Replies</h1>
  </div>
);

const Item = ({
  image,
  name,
  time,
  love,
  loved = false,
  id,
  mess,
  commentImg,
}: {
  image: string;
  name: string;
  time: any;
  love: string | number;
  loved?: boolean;
  id: string;
  mess?: string;
  commentImg?: string;
}) => (
  <div className="border border-solid border-black bg-primary p-2 flex flex-row gap-2 shadow-[4px_4px_0px_0px_#96969640]">
    <img className="w-4 h-4" src={image} alt={"avatar"} />

    <div className="flex-1 flex flex-col gap-2">
      <div className="flex flex-row">
        <h3
          className="flex-1 text-chad-Heliotrope text-sm font-bold"
          style={{
            fontFamily: "MS Sans Serif Bold",
          }}
        >
          {name}
        </h3>
        <div className="flex gap-4">
          <p>{time}</p>
          <p className="flex w-[50px] justify-center">
            {loved ? <Loved /> : <Love />}
            <span className="ml-2">{love}</span>
          </p>
          <p>{id}</p>
        </div>
      </div>
      <div className="break-all">
        {mess}
        {commentImg && (
          <img
            className="max-w-[180px] max-h-[180px]"
            src={commentImg}
            alt={"comment"}
          />
        )}
      </div>
    </div>
  </div>
);
