import { useState, useEffect } from "react";
import ButtonYellow from "../components/Button/ButtonYellow";
import CoinsCreated from "../components/card/CoinsCreated/CoinsCreated";
import CoinsHeld from "../components/card/CoinsHeld/CoinsHeld";
import Followers from "../components/card/Followers/Followers";
import Following from "../components/card/Following/Following";
import Notifications from "../components/card/Notifications/Notifications";
import Replies from "../components/card/Replies/Replies";
import WalletInfoCard from "../components/card/WalletInfoCard/WalletInfoCard";

import { useAnchorWallet } from "@solana/wallet-adapter-react";

type Props = {};

export default function Profile({}: Props) {
  const wallet = useAnchorWallet();
  const [walletAddress, setWalletAddress] = useState('');
  
  
  useEffect(() => {
    if(wallet) {
      const address = wallet.publicKey.toBase58();
      setWalletAddress(address);
    }
  }, [wallet]);
  return (
    <div className="container mx-auto flex flex-col">
      <div className="flex flex-row flex-wrap gap-4 items-center justify-center w-full">
        <ButtonYellow>Coins held</ButtonYellow>
        <ButtonYellow>Replies</ButtonYellow>
        <ButtonYellow>Notifications</ButtonYellow>
        <ButtonYellow>Coins Created</ButtonYellow>
        <ButtonYellow>Followers</ButtonYellow>
        <ButtonYellow>Following</ButtonYellow>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-6 w-full">
        <div className="flex flex-col gap-4">
          <WalletInfoCard />
          <CoinsCreated />
        </div>
        <div>
          <CoinsHeld />
        </div>

        <div className="w-full flex flex-col gap-4">
          <div>
            <Replies />
          </div>
          <div>
            <Notifications />
          </div>
        </div>
        <div className="w-full flex flex-col gap-4">
          <div>
            {
              walletAddress &&
              <Followers walletAddress={walletAddress} />
            }
          </div>
          <div>
            <Following />
          </div>
        </div>
      </div>
    </div>
  );
}
