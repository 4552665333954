function HolderDistribution() {
  return (
    <div className="sidebar-box box-shadow text-black mb-0">
      <h3 className="sidebar-box-title font-serifBold mb-2">
        <img src="/images/Group 4.png" alt="crown" className="icon" />
        Holder distribution
      </h3>
      <span className="text text-chad-Heliotrope font-serifBold">
        King of the hill progress: 67%
      </span>
      <div className="table-hoder mt-2">
        <table className="table-list">
          <tbody className="table-list">
            <tr>
              <th>
                <span className="text">1.</span>
                <span>6X8E8W (bonding curve)</span>
              </th>
              <th>
                <span className="text">29.79%</span>
              </th>
            </tr>
            <tr>
              <th>
                <span className="text">1.</span>
                <span>6X8E8W (bonding curve)</span>
              </th>
              <th>
                <span className="text">29.79%</span>
              </th>
            </tr>
            <tr>
              <th>
                <span className="text">1.</span>
                <span>6X8E8W (bonding curve)</span>
              </th>
              <th>
                <span className="text">29.79%</span>
              </th>
            </tr>
            <tr>
              <th>
                <span className="text">1.</span>
                <span>6X8E8W (bonding curve)</span>
              </th>
              <th>
                <span className="text">29.79%</span>
              </th>
            </tr>
            <tr>
              <th>
                <span className="text">1.</span>
                <span>6X8E8W (bonding curve)</span>
              </th>
              <th>
                <span className="text">29.79%</span>
              </th>
            </tr>
            <tr>
              <th>
                <span className="text">1.</span>
                <span>6X8E8W (bonding curve)</span>
              </th>
              <th>
                <span className="text">29.79%</span>
              </th>
            </tr>
            <tr>
              <th>
                <span className="text">1.</span>
                <span>6X8E8W (bonding curve)</span>
              </th>
              <th>
                <span className="text">29.79%</span>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default HolderDistribution;
