import ButtonYellow from "../components/Button/ButtonYellow";
import CoinsCreated from "../components/card/CoinsCreated/CoinsCreated";
import CoinsHeld from "../components/card/CoinsHeld/CoinsHeld";
import Followers from "../components/card/Followers/Followers";
import Following from "../components/card/Following/Following";
import WalletInfoCard from "../components/card/WalletInfoCard/WalletInfoCard";

type Props = {};

export default function Profile({}: Props) {
  return (
    <div className="container mx-auto flex flex-col">
      <div className="flex flex-row flex-wrap gap-4 items-center justify-center w-full">
        <ButtonYellow>Coins held</ButtonYellow>
        <ButtonYellow>Coins Created</ButtonYellow>
        <ButtonYellow>Followers</ButtonYellow>
        <ButtonYellow>Following</ButtonYellow>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-3 gap-6 w-full">
        <div className="flex flex-col gap-4">
          <WalletInfoCard />
          <CoinsCreated />
        </div>
        <div>
          <CoinsHeld />
        </div>

        <div className="w-full flex flex-col gap-4">
          <div>
            <Followers walletAddress="G1KfhuipQnZEYxHQLuxiH8xeUcpPxESGmkT843Dkhs8" />
          </div>
          <div>
            <Following />
          </div>
        </div>
      </div>
    </div>
  );
}
