import axios from "axios";
import Cookies from "js-cookie";
import { useState, createRef } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { END_POINT } from "../../../constants";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
if (Cookies.get("token")) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
    "token"
  )}`;
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
}


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "10px 12px",
    color: "#000",
  },
};

const ModalReply = ({
  modalIsOpen,
  setIsOpen,
  mention,
}: {
  modalIsOpen: boolean;
  setIsOpen: any;
  mention: any;
}) => {
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#000";
  }
  const { poolId } = useParams();
  const [commentContent, setCommentContent] = useState("");
  const [captcha, setCaptcha] = useState("");
  // const [mention, setMention] = useState('');
  const recaptchaRef = createRef();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      formComment: ''
    },
  });

  async function postComment(data) {
    try {
      if(!captcha) {
        toast.error('Please check on recaptcha');
        return;
      }
      console.log(data);
      const result = await axios.post(END_POINT + "/comments/", {
        content: data.formComment,
        poolId,
        mention,
      });
      if (result.status === 200) {
        setCommentContent("");
        setCaptcha('');
        setIsOpen(false);
        reset({
          formComment: "",
        })
        toast.success('Comment successfully');
      } else if (result.status === 401) {
        toast.error("You have to login");
      }
    } catch (err) {
      console.log(err);
    }
  }
  const onChangeReCaptCha = (value) => {
    setCaptcha(value);
  }
  const onExpiredReCaptCha = () => {
    setCaptcha('');
    toast.error('Expired reCaptCha');
  }
  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex justify-between items-center">
          <h2 className="font-[ThaleahFat] text-[22px]">REPLY</h2>
          <button onClick={() => setIsOpen(false)}>
            {" "}
            <img
              src="/images/detail/icon-close-modal.png"
              alt="close icon"
              width={25}
              height={25}
            />
          </button>
        </div>
        <form
          // onSubmit={(e) => e.preventDefault()}
          onSubmit={handleSubmit((data) => {
            postComment(data);
          })}
          className="flex flex-col gap-4 text-base"
        >
          <div className="bg-[#FFB5B5] border-solid border-2 border-black p-4 flex flex-col gap-2">
            <div
              className="grid grid-cols-[100px_340px] max-sm:grid-cols-1"
              style={
                {
                  // gridTemplateColumns: "100px 340px",
                }
              }
            >
              <label htmlFor="comment">Comment</label>
              <div className="input-right-field">
                <textarea
                  style={{
                    backgroundImage: "url('/images/bg_input_big.png')",
                    backgroundColor: "transparent",
                  }}
                  rows={4}
                  cols={50}
                  maxLength={200}
                  id="comment"
                  // value={commentContent}
                  onChange={(e) => setCommentContent(e.target.value)}
                  defaultValue="" {...register("formComment", { required: true })}
                  placeholder="add a comment"
                  className="placeholder:text-black bg-size100 bg-mid-center outline-none border-none py-2 px-4"
                />
                {errors.formComment?.type === 'required' && <p>This field is required</p>}
              </div>
            </div>
            
            <div className="grid grid-cols-[100px_340px] max-sm:grid-cols-1">
              <label htmlFor="comment-image">Image</label>
              <div
                style={{
                  backgroundImage: "url('/images/bg_input.png')",
                  backgroundColor: "transparent",
                }}
                className="bg-size100 bg-mid-center py-2 px-4 flex justify-between items-center"
              >
                <p>optional</p>
                <input
                  type="file"
                  id="comment-image"
                  accept="image/png, image/jpeg, image/svg"
                  placeholder="optional"
                  className="outline-none border-none w-[50%]"
                />
              </div>
            </div>
            <ReCAPTCHA
              sitekey="6LfD9O4pAAAAAHCJc9iWuo5mDKsJSuTSeoTAFARM"
              ref={recaptchaRef}
              onExpired={onExpiredReCaptCha}
              onChange={onChangeReCaptCha}
            />
          </div>
          <div className="flex justify-center">
            <button
              type="submit"
              style={{
                backgroundImage: "url(/images/bg_button_common.png)",
              }}
              className="py-2 px-10 bg-size100 bg-mid-center"
              // onClick={() => {
              //   postComment();
              // }}
            >
              Post reply
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ModalReply;
