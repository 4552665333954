import { useState, useEffect } from "react";
import Card from "../Card";
import { END_POINT } from '../../../constants';
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import Cookies from 'js-cookie';
import axios from "axios";
if(Cookies.get('token')) {
  // console.log('token', Cookies.get('token'));
  axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
}


type Props = {
  walletAddress: string
};

export default function Followers({walletAddress}: Props) {
  
  const wallet = useAnchorWallet();
  const [followersArr, setFollower] = useState([]);
  const [suggest, setSuggest] = useState([]);
  useEffect(() => {
    if(wallet) {
      // const address = wallet.publicKey.toBase58();
      axios.get(END_POINT + '/users/'+walletAddress)
        .then(response => {
          if(response.status === 200 && response.data.data) {
            setFollower(response.data.data.followers);
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
      axios.get(END_POINT + '/users/suggest/data')
      .then(response => {
        if(response.status === 200 && response.data.data) {
          setSuggest(response.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
    
  }, [wallet]);

  return (
    <Card header={CustomHeader()}>
      <div>
        <div className="flex flex-col gap-[10px] overflow-y-auto h-[30vh]">
          {
            followersArr.map((item) => 
            <Follower
              key={item.id}
              image={item.avatar}
              name={item.username}
              followers={item.followers.length}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row gap-4 items-center my-[10px]">
        <div className="flex-1 h-[1px] bg-[#404040]"></div>
        <p className="text-[#404040]">People you may know</p>
        <div className="flex-1 h-[1px] bg-[#404040]"></div>
      </div>
      <div>
        <div className="flex flex-col gap-4 overflow-y-auto">
        {
          suggest.map((item) => 
          <Follower
            key={item.id}
            image={item.avatar}
            name={item.username}
            followers={item.followers.length}
          />
        )}
        </div>
      </div>
    </Card>
  );
}

const CustomHeader = () => (
  <div className="flex flex-row gap-1 items-center">
    <h1 className="uppercase">Followers</h1>
  </div>
);

const Follower = ({
  image,
  name,
  followers,
}: {
  image: string;
  name: string;
  followers: string | number;
}) => (
  <div
    style={{
      backgroundImage: "url('/images/profile/bg_followers.png')",
      backgroundPosition: "center center",
      backgroundSize: "100% 100%",
    }}
    className="flex p-[8px] pb-[11px] gap-2 items-center"
  >
    <div className="flex-1 flex gap-2 items-center">
      <img className="w-[30px] h-[30px]" src={image} alt={"avatar"} />
      <h3 className="text-normal break-all text-white font-serifBold">
        {name}
      </h3>
    </div>
    <div className="flex gap-6 items-center">
      <p className="text-white">{followers} followers</p>
      <div>
        <img
          width={25}
          height={25}
          src="/images/icon_star2.png"
          alt="followers"
        />
      </div>
    </div>
  </div>
);
