import Card from "../Card";

import { ReactComponent as Refresh } from "../svgs/refresh.svg";

type Props = {};

export default function CoinsCreated({}: Props) {
  return (
    <Card header={CustomHeader()}>
      <div className="flex flex-col gap-4">
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
      </div>
    </Card>
  );
}

const CustomHeader = () => (
  <div className="flex flex-row gap-1 items-center">
    <h1 className="uppercase">Coins created</h1>
  </div>
);

const Item = () => (
  <div className="border border-solid border-black bg-[#FEF518] p-2 flex flex-row gap-2 shadow-[4px_4px_0px_0px_#96969640]">
    <img
      className="w-[100px] h-[100px]"
      src={
        "https://s3-alpha-sig.figma.com/img/1263/783b/37a9c6cdda9bbb4e44d5da4418a8dd4b?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PUci7F~gj1M0SaEqkCKHCeKS~qf4j5xxGF~37mbbekPeJfaglQJI6P2xQF2qBO-K4yy5h0mX~TN8Fr9m0vHxbPA6GS69W1ly82FdZc8j-LYPpxromlaR36aFFj7UG21l7NjIbJtKPnsyZS9VeUOOIHjJlfbvvM1CDld8ORJTRpAGvLfK7ubE-pzuj5IMtVylSaaMiqJnvr4w6fuPIZs2Sc9l3C6rI9~83u81VC9Uqiaf74PVdAuJqdOKRfYPx-9Pwv83iO-qzfGS3t7hIrC-SBkrHpzsTULKOMYSuW3Jbvo0k57Qx5xW3shvwQCCuW8TxZPvIITeVrKmSVVgcfEhYA__"
      }
      alt={"avatar"}
    />

    <div className="flex-1 flex flex-col gap-2">
      <div className="flex flex-row">
        <h1
          className="text-[14px] flex-1"
          style={{
            fontFamily: "MS Sans Serif Bold",
          }}
        >
          Trans Trump - TRAMP
        </h1>
        <Refresh />
      </div>

      <div className="bg-[#591111] border border-solid border-black flex flex-row items-center text-white px-2 py-1 gap-1 text-[14px]">
        <p
          style={{
            fontFamily: "MS Sans Serif",
          }}
        >
          Market cap:
        </p>
        <p
          style={{
            fontFamily: "MS Sans Serif Bold",
          }}
          className="flex-1"
        >
          48.39K
        </p>
        <img src="/images/profile/crown.png" alt="crown" className="w-[21px]" />
      </div>

      <p
        style={{
          fontFamily: "MS Sans Serif",
        }}
        className="text-[#AD20EF] text-[14px]"
      >
        Replies: 9
      </p>
    </div>
  </div>
);
