import { useAnchorWallet, useWallet, useConnection } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import {
  AnchorProvider,
  setProvider,
} from "@project-serum/anchor";

import { createChad } from "../web3/pump";
import { sleep } from "../helpers/helpers";

import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import Modal from "react-modal";

import axios from "axios";

import { truncateString } from "../helpers/helpers";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

import { Form, Input } from "antd";

import { quickNodeUpload, pinataUpload, pinataGetFile } from "../web3/program";

import ReactLoading from "react-loading";

import { END_POINT } from '../constants';

import { useForm } from "react-hook-form";


const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px",
  },
};
const customMenuStyle = {
  content: {
    top: "0",
    right: "0",
    left: "auto",
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
    maxWidth: "260px",
    width: "260px",
    height: "100vh",
  },
};

export default function Header() {
  
  const { disconnect } = useWallet();

  const { connection } = useConnection();
  const wallet = useAnchorWallet();
  const provider = new AnchorProvider(connection, wallet, {});
  setProvider(provider);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalCreateIsOpen, setIsOpenCreate] = useState(false);
  const [modalProfileIsOpen, setIsOpenProfile] = useState(false);
  const [modalProfileUpdateIsOpen, setIsOpenProfileUpdate] = useState(false);
  const [modalMenuIsOpen, setIsOpenMenu] = useState(false);
  const [isShowMoreOption, setIsShowMoreOption] = useState(false);

  let moreOptions;
  if (isShowMoreOption) {
    moreOptions = (
      <>
        <div className="row">
          <label className="name">Twitter</label>
          <Form.Item name="twitter" >
            <Input
              type="string"
              className="common-input"
              placeholder="(optional)"
            />
          </Form.Item>
        </div>
        <div className="row">
          <label className="name">Telegram</label>
          <Form.Item name="telegram" >
            <Input
              type="string"
              className="common-input"
              placeholder="(optional)"
            />
          </Form.Item>
        </div>
        <div className="row">
          <label className="name">Website</label>
          <Form.Item name="website" >
            <Input
              type="string"
              className="common-input"
              placeholder="(optional)"
            />
          </Form.Item>
        </div>
      </>
    );
  } else {
    moreOptions = "";
  }


  const [createChadForm] = Form.useForm();
  const [tokenIcon, setTokenIcon] = useState("");

  const [selectedBtnId, setSelectedBtnId] = useState();
  const [btnLoading, setBtnLoading] = useState(false);
  const addSpinner = (btnId) => {
    setBtnLoading(true);
    setSelectedBtnId(btnId);
  };
  const removeSpinner = (btnId) => {
    setBtnLoading(false);
    setSelectedBtnId(null);
  };


  const handleCreateChad = async (values) => {
    let formData = values;
    if (tokenIcon) {
      formData = { ...values, tokenIcon };
    }

    const btnID = "create-chad";
    addSpinner(btnID);

    const created = await createChad(formData, wallet, connection);
    console.log(`created`, created)
    if (created.status == "success") {
      await sleep(20000);
      removeSpinner(btnID);
      // setNewLaunchpad(auctionCreated.address);
      // setICongratulationModalOpen(true);
      toast.success(created.message);
      createChadForm.resetFields();
    } else {
      removeSpinner(btnID);
      toast.error(created.message);
    }



  }
  const handleUploadFile = async (file, field) => {
    // const tokenIconUploaded = await quickNodeUpload(file, field);
    const tokenIconUploaded = await pinataUpload(file, field);
    if (field === "tokenIcon") setTokenIcon(tokenIconUploaded);
  };
  interface Iuser {
    username: string,
    bio: string
  }

  const [user, setUser] = useState<Iuser>();
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const [followers, setFollowers] = useState(0);
  const [walletAddress, setWalletAddress] = useState('');
  
  useEffect(() => {
    if(wallet) {
      console.log('====>wallet.publicKey.toBase58()');
      console.log(wallet.publicKey.toBase58());
      const address = wallet.publicKey.toBase58();
      setWalletAddress(address);
      if(address) {
      axios.get(END_POINT + '/users/'+address)
        .then(response => {
          if(response.status === 200 && response.data.data) {
            setUser(response.data.data);
            setUsername(response.data.data.username);
            setBio(response.data.data.bio);
            setFollowers(response.data.data.followers.length);
            reset({
              formUsername: response.data.data.username,
              formBio: response.data.data.bio
            });
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    }
    // }
  }, [wallet]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      formUsername: username,
      formBio: bio,
    },
  });

  const updateUser = async (data) => {
    try {
      const result = await axios.post(END_POINT + '/users/update', {
        username: data.formUsername,
        bio: data.formBio
      });
      if(result.status === 200) {
        toast.success('User udpated');
      }
    } catch(err) {
      toast.error('There is an error');
      console.log(err);
    }
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setIsOpen(false);
        }}
        style={customStyles}
        className="modal"
        overlayClassName="overlay"
        ariaHideApp={false}
      >
        <button
          className="close-modal"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <img src="/images/btn_close.png" alt="Close" />
        </button>
        <h2 className="sidebar-box-title">
          <img src="/images/icon_coin1.png" alt="icon" className="icon" />
          How it works
        </h2>
        <div className="modal-content">
          <p className="desc">
            Chad prevents rugs by making sure that all created tokens are safe.
            Each coin on Chad is a 
            <span className="text-purple">fair-launch</span> with no{" "}
            <span className="text-red">presale</span> and 
            <span className="text-red">no team allocation</span>.
          </p>
          <ul className="list-how">
            <li>
              <span className="bold">Step 1:</span>&nbsp; pick a coin that you
              like.
            </li>
            <li>
              <span className="bold">Step 2:</span>&nbsp; buy the coin on the
              bonding curve.
            </li>
            <li>
              <span className="bold">Step 3:</span>&nbsp; sell at any time to
              lock in your profits or losses.
            </li>
            <li>
              <span className="bold">Step 4:</span>&nbsp; pick a coin that you
              like.
            </li>
            <li>
              <span className="bold">Step 5:</span>&nbsp; when enough people buy
              on the bonding curve it reaches a market cap of $69k.
            </li>
            <li>
              <span className="bold">Step 6:</span>&nbsp; $12k of liquidity is
              then deposited in raydium and burned.
            </li>
          </ul>
        </div>
        <div
          className="btn-common"
          onClick={() => {
            setIsOpen(false);
          }}
        >
          I'm ready to Chad
        </div>
      </Modal>

      <Modal
        isOpen={modalMenuIsOpen}
        onRequestClose={() => {
          setIsOpenMenu(false);
        }}
        style={customMenuStyle}
        className="modal modal-menu"
        overlayClassName="overlay overlay-menu"
        ariaHideApp={false}
      >
        <button
          className="close-modal"
          onClick={() => {
            setIsOpenMenu(false);
          }}
        >
          <img src="/images/btn_close.png" alt="Close" />
        </button>
        <a href="./" className="logo-sp">
          <img src="/images/logo_sp.png" alt="menu" />
        </a>
        <div className="btn-connect-wrp">
          {!wallet ? (
            <WalletMultiButton />
          ) : (
            <>
              <button
                className="btn-connected"
                onClick={() => {
                  setIsOpenProfile(true);
                }}
              >
                <img src="/images/favicon.png" /> @
                {truncateString(wallet.publicKey.toBase58(), 4, 3)}
              </button>
            </>
          )}
        </div>

        <ul className="menu-sp">
          <li>
            <div
              className="btn-link"
              onClick={() => {
                setIsOpenCreate(true);
              }}
            >
              Start a new coin
            </div>
          </li>
          {wallet && (
            <li>
              <Link className="btn-link" to="/profile">
                profile
              </Link>
            </li>
          )}

          <li>
            <div
              className="btn-link"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              How it works
            </div>
          </li>
          <li>
            <a className="btn-link">Support</a>
          </li>
        </ul>
        <ul className="menu-social">
          <li>
            <a href="#">
              <img src="/images/icon_x.png" alt="x" />
            </a>
          </li>
          <li>
            <a href="#">
              <img src="/images/icon_telegram.png" alt="telegram" />
            </a>
          </li>
        </ul>
      </Modal>

      <Modal
        isOpen={modalCreateIsOpen}
        onRequestClose={() => {
          setIsOpenCreate(false);
        }}
        style={customStyles}
        className="modal"
        overlayClassName="overlay"
        ariaHideApp={false}
      >
        <button
          className="close-modal"
          onClick={() => {
            setIsOpenCreate(false);
          }}
        >
          <img src="/images/btn_close.png" alt="Close" />
        </button>
        <h2 className="sidebar-box-title">
          <img src="/images/icon_coin1.png" alt="icon" className="icon" />
          Create a new coin
        </h2>
        <div className="modal-content modal-form">


          <Form form={createChadForm} onFinish={handleCreateChad}>
            <div className="row">
              <label className="name">Name</label>
              <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                  ]}
                >
                  <Input
                    type="string"
                    className="common-input"
                  />
                </Form.Item>
            </div>

            <div className="row">
              <label className="name">Symbol</label>
              <Form.Item
                  name="symbol"
                  rules={[
                    {
                      required: true,
                      message: "Symbol is required",
                    },
                  ]}
                >
                  <Input
                    type="string"
                    className="common-input"
                  />
                </Form.Item>
            </div>

            <div className="row">
              <label className="name">Description</label>
              <Form.Item
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Description is required",
                    },
                  ]}
                >
                  <Input
                    type="string"
                    className="common-input"
                  />
                </Form.Item>
            </div>
            <div className="row">
              <label className="name">Image</label>
              <Form.Item>
                <input
                  type="file"
                  className="input-img"
                  accept="image/png, image/jpeg, image/jpg"
                  name="tokenIcon"
                  onChange={(e) => {
                    handleUploadFile(e.target.files[0], "tokenIcon");
                  }}
                />
              </Form.Item>
            </div>
            <div
              className={"btn-show-more " + (isShowMoreOption ? "active" : "")}
              onClick={() => {
                setIsShowMoreOption(!isShowMoreOption);
              }}
            >
              Show more option
            </div>
            {moreOptions}


            <button
              className="btn-common"
              id="create-chad"
              disabled={ btnLoading && selectedBtnId === "create-chad" ? true : false }
            >
              <span>Create coin</span>
              {btnLoading && selectedBtnId === "create-chad" && (
                <ReactLoading type="bars" height={20} width={20} />
              )}
            </button>
            
          </Form>
          <p className="cost-info">Cost to deploy: 0.02 SOL</p>
        </div>
      </Modal>

      {wallet && (
        <Modal
          isOpen={modalProfileIsOpen}
          onRequestClose={() => {
            setIsOpenProfile(false);
          }}
          style={customStyles}
          className="modal modal-profile"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <button
            className="close-modal"
            onClick={() => {
              setIsOpenProfile(false);
            }}
          >
            <img src="/images/btn_close.png" alt="Close" />
          </button>
          <h2 className="sidebar-box-title modal-box-title">
            <img src="/images/icon_profile5.png" alt="icon" className="icon" />
            PROFILE
          </h2>
          <div className="modal-content">
            <div className="profile-head">
              <img className="logo" src="/images/favicon.png" />
              <div className="name">
                @{truncateString(wallet.publicKey.toBase58(), 4, 3)}
              </div>
              <div className="followers">{followers}&nbsp; followers</div>
            </div>
            <div className="address">
              <p>{wallet.publicKey.toBase58()}</p>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(wallet.publicKey.toBase58());
                  toast.success(
                    `Copied: ${truncateString(
                      wallet.publicKey.toBase58(),
                      16,
                      5
                    )}`
                  );
                }}
                className="copy"
              ></button>
            </div>
          </div>
          <div className="btn-group">
            <div
              className="btn-common"
              onClick={() => {
                setIsOpenProfile(false);
                setIsOpenProfileUpdate(true);
              }}
            >
              Edit profile
            </div>
            <div
              className="btn-common grey"
              onClick={() => {
                disconnect();
                // removeCookie('SolanaSignedIn');
                Cookies.remove("token");
                window.location.reload();
              }}
            >
              Disconnect
            </div>
          </div>
        </Modal>
      )}

      {/* update profile */}
      {wallet && (
        <Modal
          isOpen={modalProfileUpdateIsOpen}
          onRequestClose={() => {
            setIsOpenProfileUpdate(false);
          }}
          style={customStyles}
          className="modal modal-profile"
          overlayClassName="overlay"
          ariaHideApp={false}
        >
          <button
            className="close-modal"
            onClick={() => {
              setIsOpenProfileUpdate(false);
            }}
          >
            <img src="/images/btn_close.png" alt="Close" />
          </button>
          <h2 className="sidebar-box-title modal-box-title">
            <img src="/images/icon_profile5.png" alt="icon" className="icon" />
            EDIT PROFILE
          </h2>
            <form
              onSubmit={handleSubmit((data) => {
                updateUser(data);
              })}
            >
            <div className="modal-content modal-form">
              
                <div className="profile-head">
                  <img className="logo" src="/images/favicon.png" />
                  <div className="name">
                    @{truncateString(wallet.publicKey.toBase58(), 4, 3)}
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="" className="name">
                    Username
                  </label>
                  {/* <input type="text" value={username} onChange={(e) => {setUsername(e.target.value)}} className="common-input" /> */}
                  <p className="right">
                    <input type="text" defaultValue={username} {...register("formUsername", { required: true, pattern: /^[a-zA-Z0-9_.-]*$/i })} className="common-input" />
                    {errors.formUsername?.type === 'required' && <p>This field is required</p>}
                    {errors.formUsername?.type === 'pattern' && <p>Wrong username format</p>}
                  </p>
                </div>
                <div className="row">
                  <label htmlFor="" className="name">
                    Bio
                  </label>
                  {/* <input type="text" value={bio} onChange={(e) => {setBio(e.target.value)}} className="common-input" /> */}
                  <div className="right">
                    <input type="text" defaultValue={bio} {...register("formBio", { required: true })} className="common-input" />
                    {errors.formBio && <p>This field is required</p>}
                  </div>
                </div>
                <div className="row">
                  <label htmlFor="" className="name">
                    Avatar
                  </label>
                  <input type="file" name="" />
                </div>
              
            </div>
            <div className="btn-group">
              <button
                type="submit"
                className="btn-common"
              >SAVE</button>
            </div>
              </form>
        </Modal>
      )}

      <div className="container mx-auto">
        <header>
          <div className="left">
            <a href="/" className="logo">
              <img src="/images/logo.png" alt="chad" />
            </a>
          </div>

          <ul className="right-menu pc">
            <li>
              <div
                className="btn-link"
                onClick={() => {
                  setIsOpenCreate(true);
                }}
              >
                Start a new coin
              </div>
            </li>
            {wallet && (
              <li>
                <Link className="btn-link" to="/profile">
                  profile
                </Link>
              </li>
            )}

            <li>
              <div
                className="btn-link"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                How it works
              </div>
            </li>
            <li>
              <a className="btn-link">Support</a>
            </li>
          </ul>

          <div className="right">
            <ul className="right-buttons md">
              <li>
                <a href="#">
                  <img src="/images/icon_x.png" alt="x" />
                </a>
              </li>
              <li>
                <a href="#">
                  <img src="/images/icon_telegram.png" alt="telegram" />
                </a>
              </li>
            </ul>
            <div className="btn-connect-wrp md">
              {!wallet ? (
                <WalletMultiButton />
              ) : (
                <>
                  <button
                    className="btn-connected"
                    onClick={() => {
                      setIsOpenProfile(true);
                    }}
                  >
                    <img src="/images/favicon.png" /> @
                    {truncateString(wallet.publicKey.toBase58(), 4, 3)}
                  </button>
                </>
              )}
            </div>
            <button
              className="sp btn-menu"
              onClick={() => {
                setIsOpenMenu(true);
              }}
            >
              <img src="/images/icon_menu.png" alt="menu" />
            </button>
          </div>
        </header>
      </div>
    </>
  );
}
