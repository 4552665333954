import { useState, useEffect } from "react";
import Card from "../Card";
import { END_POINT } from '../../../constants';
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import Cookies from 'js-cookie';
import axios from "axios";
if(Cookies.get('token')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
}


type Props = {};

export default function Following({}: Props) {

  const [followingArr, setFollower] = useState([]);
  const [suggest, setSuggest] = useState([]);
  const wallet = useAnchorWallet();
  useEffect(() => {
    if(wallet) {
        const address = wallet.publicKey.toBase58();
        axios.get(END_POINT + '/users/'+address)
        .then(response => {
          if(response.status === 200 && response.data.data) {
            setFollower(response.data.data.following);
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
      axios.get(END_POINT + '/users/suggest/data')
      .then(response => {
        if(response.status === 200 && response.data.data) {
          setSuggest(response.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [wallet]);

  return (
    <Card header={CustomHeader()}>
      <div className="flex flex-col gap-4 overflow-y-auto h-[50vh]">
      {
        followingArr.map((item) => 
        <Item
          key={item.id}
          image={item.avatar}
          name={item.username}
          following={item.followers.length}
        />
      )}
      </div>
    </Card>
  );
}

const CustomHeader = () => (
  <div className="flex flex-row gap-1 items-center">
    <h1 className="uppercase">Following</h1>
  </div>
);

const Item = ({
  image,
  name,
  following,
}: {
  image: string;
  name: string;
  following: string | number;
}) => (
  <div className="border border-solid border-[#C5B4B4] p-2 flex flex-row gap-4 shadow-[4px_4px_0px_0px_#96969640]">
    <img className="w-[100px] h-[100px]" src={image} alt={"avatar"} />

    <div className="flex flex-col gap-2 w-full">
      <div className="flex justify-between">
        <h3 className="flex-1 text-chad-Heliotrope text-xl font-serifBold overflow-clip break-all">
          {name}
        </h3>
        <div>
          <img
            width={25}
            height={25}
            src="/images/icon_star1.png"
            alt="following star"
          />
        </div>
      </div>
      <p className="text-[#1A73E8]">{following}&nbsp; following</p>
      <div className="flex gap-2 items-center">
        <p>Coins created</p>
        <div className="flex gap-2">
          <img
            width={25}
            height={25}
            src="/images/profile/avatar.png"
            alt="coins created by"
          />
          <img
            width={25}
            height={25}
            src="/images/profile/avatar.png"
            alt="coins created by"
          />
          <img
            width={25}
            height={25}
            src="/images/profile/avatar.png"
            alt="coins created by"
          />
        </div>
      </div>
    </div>
  </div>
);
