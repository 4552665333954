
import {
	PublicKey,
    SYSVAR_RENT_PUBKEY,
    SystemProgram,
    Transaction,
    ComputeBudgetProgram,
    LAMPORTS_PER_SOL
} from '@solana/web3.js';
// import {
//     Program
// } from "@project-serum/anchor";

import { 
    createTransferInstruction, 
    getAssociatedTokenAddress, 
    createAssociatedTokenAccountInstruction, 
    TOKEN_PROGRAM_ID, getAccount, ASSOCIATED_TOKEN_PROGRAM_ID
} from "@solana/spl-token";

import { BN } from "bn.js";

import * as spl from "@solana/spl-token";
import { ASSOCIATED_PROGRAM_ID, associatedAddress } from '@project-serum/anchor/dist/cjs/utils/token';

import { getProgramId, getProgram, quickNodeGetFile, quickNodeUploadMetadata } from "./program";

import { AnchorWallet } from '@solana/wallet-adapter-react';

interface PoplData {
    poolId: string;
    token0Amount: string;
    token0Mint: string;
    token1Amount: string;
    token1Mint: string;
}

const METADATA_SEED = "metadata";
const TOKEN_METADATA_PROGRAM_ID = new PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s");

export const createChad = async(formData, wallet: AnchorWallet, connection) => {
    // console.log(`formData`, formData)
    const program   = getProgram()
    const programId = getProgramId()
      
    const random = Math.floor(Math.random() * 9999);
    console.log(`random`, random)
    const this_random = new BN(random);

    let metadataUri = "";
    const metadataCreate = {
        name: formData.name,
        symbol: formData.symbol,
        description: formData.description,
        image: formData.tokenIcon ? quickNodeGetFile(formData.tokenIcon) : "",
        extensions: {
            website: formData.website ? formData.website : "",
            twitter: formData.twitter ? formData.twitter : "",
            telegram: formData.telegram ? formData.telegram : "",
        }
    };
    const uploadedMetadata = await quickNodeUploadMetadata(
        metadataCreate,
        formData.symbol
    );
    metadataUri = quickNodeGetFile(uploadedMetadata);

    let metadata = {
        name: formData.name,
        symbol: formData.symbol,
        uri: metadataUri,
        random: this_random
    };

    const [token_1_mint] = PublicKey.findProgramAddressSync(
        [
            Buffer.from(metadata.symbol), 
            this_random.toArray('be', 4), 
            wallet.publicKey.toBuffer()
        ],
        programId
    );
    console.log("mint", token_1_mint.toBase58());
   
    const [metadataAddress] = PublicKey.findProgramAddressSync(
        [
            Buffer.from(METADATA_SEED),
            TOKEN_METADATA_PROGRAM_ID.toBuffer(),
            token_1_mint.toBuffer(),
        ],
        TOKEN_METADATA_PROGRAM_ID
    );
    console.log("metadataAddress", metadataAddress.toBase58());
 
    let thisU16 = new BN("1");
    const [amm_config] = PublicKey.findProgramAddressSync(
        [Buffer.from("amm_config"), thisU16.toArray("be", 2) ],
        programId
    );

    const [authority] = PublicKey.findProgramAddressSync(
        [Buffer.from("vault_and_lp_mint_auth_seed")],
        programId
    );
    
    const [pool_state] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool"), amm_config.toBuffer(), spl.NATIVE_MINT.toBuffer(), token_1_mint.toBuffer()],
        programId
    );  

    const [token_0_vault] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool_vault"), pool_state.toBuffer(), spl.NATIVE_MINT.toBuffer()],
        programId
    );

    const [token_1_vault] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool_vault"), pool_state.toBuffer(), token_1_mint.toBuffer()],
        programId
    );

    const [create_pool_fee] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool_vault"), pool_state.toBuffer(), token_1_mint.toBuffer()],
        programId
    );

    let pool_fee_account = await spl.getAssociatedTokenAddress(
        spl.NATIVE_MINT,
        new PublicKey("FsEBZkDHcBkvCpzy3v8Vw18iEKs86nmezh7gFkXobfaC")
    );
    console.log("pool_fee_account",pool_fee_account.toBase58());

    const context = {
        creator: wallet.publicKey,
        ammConfig: amm_config,
        metadata: metadataAddress,
        authority: authority,
        poolState: pool_state,
        token0Mint: spl.NATIVE_MINT,
        token1Mint: token_1_mint,
        token0Vault: token_0_vault,
        token1Vault: token_1_vault,
        createPoolFee: pool_fee_account,

        tokenProgram: TOKEN_PROGRAM_ID,
        associatedTokenProgram: ASSOCIATED_PROGRAM_ID,
        systemProgram: SystemProgram.programId,
        rent: SYSVAR_RENT_PUBKEY,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        
    };
  
    try{
        const txHash = await program.methods
            .initialize(metadata)
            .accounts(context)
            .rpc({skipPreflight:true});

        await connection.confirmTransaction(txHash, 'finalized');
        console.log(`  https://explorer.solana.com/tx/${txHash}?cluster=devnet`);

        return {
            status: 'success',
            message: 'Chad has been created.'
        }
    } catch (e) {
        console.log(e)
        return {
            status: 'error',
            message: 'Unable to create Chad.'
        }
    }
}

export const swapbasein = async(poolData: PoplData, amountIn: number, minimumAmountOut: number, slipage: number, wallet: AnchorWallet, connection) => {

    const program   = getProgram();
    const programId = getProgramId();
    
    const token_1_mint = new PublicKey(poolData.token1Mint);
    console.log("token_1_mint", token_1_mint.toBase58());

    
    let thisU16 = new BN("1");
    const [amm_config] = PublicKey.findProgramAddressSync(
        [Buffer.from("amm_config"), thisU16.toArray("be", 2) ],
        programId
    );

    const [authority] = PublicKey.findProgramAddressSync(
        [Buffer.from("vault_and_lp_mint_auth_seed")],
        programId
    );
      
    const [pool_state] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool"), amm_config.toBuffer(), spl.NATIVE_MINT.toBuffer(), token_1_mint.toBuffer()],
        programId
    );  

    const [token_0_vault] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool_vault"), pool_state.toBuffer(), spl.NATIVE_MINT.toBuffer()],
        programId
    );

    const [token_1_vault] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool_vault"), pool_state.toBuffer(), token_1_mint.toBuffer()],
        programId
    );

    let input_token_account = await spl.getAssociatedTokenAddress(
        spl.NATIVE_MINT,
        wallet.publicKey
    );

    let ouput_token_account = await spl.getAssociatedTokenAddress(
        token_1_mint,
        wallet.publicKey
    );

    let tx = new Transaction();
    try {
        await spl.getAccount(connection, ouput_token_account)
    } catch (e) {
        tx.add(
            spl.createAssociatedTokenAccountInstruction(
                wallet.publicKey,
                ouput_token_account,
                wallet.publicKey,
                token_1_mint
            )
        );
    }

    const context1 = {
        payer: wallet.publicKey,
        authority: authority,
        ammConfig: amm_config,
        poolState: pool_state,
        inputTokenAccount: input_token_account,
        outputTokenAccount: ouput_token_account,
        inputVault: token_0_vault,
        outputVault: token_1_vault,
        tokenProgram: TOKEN_PROGRAM_ID,
        inputTokenMint: spl.NATIVE_MINT,
        outputTokenMint: token_1_mint,
    };

    try{
        const txHash = await program.methods
        .swapBaseInput(
            new BN(amountIn*LAMPORTS_PER_SOL), // amountIn
            new BN(minimumAmountOut*10**6) // minimumAmountOut
        )
        .preInstructions(tx.instructions)
        .accounts(context1)
        .rpc({skipPreflight:true})

        await connection.confirmTransaction(txHash, 'finalized');
        console.log(`  https://explorer.solana.com/tx/${txHash}?cluster=devnet`);

        return {
            status: 'success',
            message: 'Swapped.'
        }
    } catch (e) {
        console.log(e)
        return {
            status: 'error',
            message: 'Unable to swap.'
        }
    }
}

export const swapbaseinsell = async(poolData: PoplData, amountIn: number, minimumAmountOut: number, slipage: number, wallet: AnchorWallet, connection) => {

    const program   = getProgram();
    const programId = getProgramId();
    
    const token_1_mint = new PublicKey(poolData.token1Mint);

    let thisU16 = new BN("1");
    const [amm_config] = PublicKey.findProgramAddressSync(
        [Buffer.from("amm_config"), thisU16.toArray("be", 2) ],
        programId
    );

    const [authority] = PublicKey.findProgramAddressSync(
        [Buffer.from("vault_and_lp_mint_auth_seed")],
        programId
    );
      
    const [pool_state] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool"), amm_config.toBuffer(), spl.NATIVE_MINT.toBuffer(), token_1_mint.toBuffer()],
        programId
    );  

    const [token_0_vault] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool_vault"), pool_state.toBuffer(), spl.NATIVE_MINT.toBuffer()],
        programId
    );

    const [token_1_vault] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool_vault"), pool_state.toBuffer(), token_1_mint.toBuffer()],
        programId
    );

    let input_token_account = await spl.getAssociatedTokenAddress(
        token_1_mint,
        wallet.publicKey
    );

    let ouput_token_account = await spl.getAssociatedTokenAddress(
        spl.NATIVE_MINT,
        wallet.publicKey
    );

    let tx = new Transaction();
    try {
        await spl.getAccount(connection, ouput_token_account)
    } catch (e) {
        tx.add(
            spl.createAssociatedTokenAccountInstruction(
                wallet.publicKey,
                ouput_token_account,
                wallet.publicKey,
                spl.NATIVE_MINT
            )
        );
    }

    const context1 = {
        payer: wallet.publicKey,
        authority: authority,
        ammConfig: amm_config,
        poolState: pool_state,
        inputTokenAccount: input_token_account,
        outputTokenAccount: ouput_token_account,
        inputVault: token_1_vault,
        outputVault: token_0_vault,
        tokenProgram: TOKEN_PROGRAM_ID,
        inputTokenMint: token_1_mint,
        outputTokenMint: spl.NATIVE_MINT,
    };

    try{
        const txHash = await program.methods
        .swapBaseInput(
            new BN(amountIn*10**6), // amountIn
            new BN(minimumAmountOut*LAMPORTS_PER_SOL) // minimumAmountOut
        )
        .preInstructions(tx.instructions)
        .accounts(context1)
        .rpc({skipPreflight:true})

        await connection.confirmTransaction(txHash, 'finalized');
        console.log(`  https://explorer.solana.com/tx/${txHash}?cluster=devnet`);

        return {
            status: 'success',
            message: 'Swapped.'
        }
    } catch (e) {
        console.log(e)
        return {
            status: 'error',
            message: 'Unable to swap.'
        }
    }
}

export const swapbaseout = async(poolData: PoplData, maxAmountIn: number, amountOut: number, slipage: number, wallet: AnchorWallet, connection) => {
    
    const program   = getProgram();
    const programId = getProgramId();

    const token_1_mint = new PublicKey(poolData.token1Mint);
    console.log("token_1_mint",token_1_mint.toBase58());
   
    let thisU16 = new BN("1");
    const [amm_config] = PublicKey.findProgramAddressSync(
        [Buffer.from("amm_config"), thisU16.toArray("be", 2)],
        programId
    );

    const [authority] = PublicKey.findProgramAddressSync(
        [Buffer.from("vault_and_lp_mint_auth_seed")],
        programId
    );
     
    const [pool_state] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool"), amm_config.toBuffer(), spl.NATIVE_MINT.toBuffer(), token_1_mint.toBuffer()],
        programId
    );  

    const [token_0_vault] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool_vault"), pool_state.toBuffer(), spl.NATIVE_MINT.toBuffer()],
        programId
    );

    const [token_1_vault] = PublicKey.findProgramAddressSync(
        [Buffer.from("pool_vault"), pool_state.toBuffer(), token_1_mint.toBuffer()],
        programId
    );

    let input_token_account = await spl.getAssociatedTokenAddress(
        spl.NATIVE_MINT,
        wallet.publicKey
    );

    let ouput_token_account = await spl.getAssociatedTokenAddress(
        token_1_mint,
        wallet.publicKey
    );

    let tx = new Transaction();
    try {
        await spl.getAccount(connection, ouput_token_account)
    } catch (e) {
        tx.add(
            spl.createAssociatedTokenAccountInstruction(
                wallet.publicKey,
                ouput_token_account,
                wallet.publicKey,
                token_1_mint
            )
        );
    }

    const context1 = {
        payer: wallet.publicKey,
        authority: authority,
        ammConfig: amm_config,
        poolState: pool_state,
        inputTokenAccount: input_token_account,
        outputTokenAccount: ouput_token_account,
        inputVault: token_0_vault,
        outputVault: token_1_vault,
        tokenProgram: TOKEN_PROGRAM_ID,
        
        inputTokenMint: spl.NATIVE_MINT,
        outputTokenMint: token_1_mint,
    };

    try{
        const txHash = await program.methods
        .swapBaseOutput(
            new BN(maxAmountIn*LAMPORTS_PER_SOL), // maxAmountIn
            new BN(amountOut*10**6)  /// amountOut
        )
        .preInstructions(tx.instructions)
        .accounts(context1)
        .rpc({skipPreflight:true})
  
        await connection.confirmTransaction(txHash, 'finalized');
        console.log(`  https://explorer.solana.com/tx/${txHash}?cluster=devnet`);

        return {
            status: 'success',
            message: 'Swapped.'
        }
    } catch (e) {
        console.log(e)
        return {
            status: 'error',
            message: 'Unable to swap.'
        }
    }
}