function Info() {
  return (
    <div className="sidebar-box box-shadow">
      <h3 className="sidebar-box-title text-black mb-3">
        <img src="/images/Group 4.png" alt="crown" className="icon" /> Info
      </h3>
      <div className="sidebar-box-info text-black">
        <div className="sidebar-info-sosial">
          <div>
            <div className="sidebar-info-sosial-logo">
              <img src="/images/Logo_token.png" alt="crown" className="logo" />
              <div className="sosial">
                <div className="sosial-info">
                  <p className="info text-chad-Heliotrope">
                    <span className="text">Created by</span>{" "}
                    <img src="/images/info.png" alt="img" />{" "}
                    <span className="text">8KEnfj</span>
                  </p>
                </div>
                <p>
                  <span className="text-trea font-serifBold">
                    BED BATH & BEYOND - BBBY
                  </span>
                </p>
                <p className="flex gap-2">
                  {" "}
                  <a href="#">
                    <img src="/images/x.png" alt="img" />
                  </a>
                  <a href="#">
                    {" "}
                    <img src="/images/tele.png" alt="img" />
                  </a>
                  <a href="#">
                    {" "}
                    <img src="/images/inter.png" alt="img" />
                  </a>
                </p>
              </div>
            </div>
            <span className="text">
              Heyy! Im tramp, join me live on kek and help me beat boden.
              <br />
              Feet at 10k MCAP, boobs at 30k mcap, hittin live w stormy daniels
              at 100k.
            </span>
          </div>
        </div>
        <div className="sidebar-info-boding">
          <h3 className="text font-serifBold text-center w-full">
            Bonding curve progress: 89%
          </h3>
          <div className="hr">
            <hr />
          </div>
          <span className="text">
            when the market cap reaches $0 all the liquidity from the bonding
            curve will be <br />
            deposited into Raydium and burned. progression increases as the
            price goes up.
            <br />
            there are 0 tokens still available for sale in the bonding <br />
            and there is 0 SOL in the bonding curve.
          </span>
        </div>
      </div>
    </div>
  );
}

export default Info;
