import {
  copyToClipboard,
  formatNumber,
  truncateString,
} from "../../../helpers/helpers";

const TableMyHolding = () => {
  return (
    <table className="detail-trades-row">
      <tbody className="detail-trades-row">
        <tr
          style={{ display: "grid" }}
          className="row-my-holding detail-trades-title grid title font-serifBold max-xl:grid-cols-[2fr_3fr_2fr_2fr_2fr_2fr_0.5fr] grid-cols-[2fr_2fr_2fr_2fr_2fr_2fr_1fr]"
        >
          <th>
            <span>Token</span>
          </th>
          <th>
            <span>Initials</span>
          </th>
          <th>
            <span>Time</span>
          </th>
          <th>
            <span>Amount</span>
          </th>
          <th>
            <span>Worth</span>
          </th>
          <th style={{ gridColumn: "6/8" }} className="xl:text-nowrap">
            <span>Change in P&L 24h</span>
          </th>
          <th>
            <span></span>
          </th>
        </tr>
        <RowMyHolding
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          time="about 1 hour ago"
          amount={formatNumber(34354212.12)}
          worth="456"
          PL={1272}
          option={false}
        />
        <RowMyHolding
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          time="about 1 hour ago"
          amount={formatNumber(34354212.12)}
          worth="456"
          PL={-3}
          option={false}
        />
        <RowMyHolding
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          time="about 1 hour ago"
          amount={formatNumber(34354212.12)}
          worth="456"
          PL={123}
          option={false}
        />
        <RowMyHolding
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          time="about 1 hour ago"
          amount={formatNumber(34354212.12)}
          worth="456"
          PL={-4}
          option={false}
        />
        <RowMyHolding
          image="/images/avatar.png"
          name="AXnaskq"
          initial={"0xCPMMoo8L3F4NbTegBCKVNunggL7H1ZpdTHKxQB5qKP1C"}
          time="about 1 hour ago"
          amount={formatNumber(34354212.12)}
          worth="456"
          PL={-98}
          option={false}
        />
      </tbody>
    </table>
  );
};

const RowMyHolding = ({
  image,
  name,
  time,
  initial,
  amount,
  worth,
  PL,
  option,
}: {
  image: string;
  name: string;
  time: string;
  initial: string;
  amount: string;
  worth: string;
  PL: number;
  option;
}) => {
  return (
    <tr
      style={{ display: "grid" }}
      className={`row-my-holding detail-trades-title row-thread grid max-xl:grid-cols-[2fr_3fr_2fr_2fr_2fr_2fr_0.5fr] grid-cols-[2fr_2fr_2fr_2fr_2fr_2fr_1fr] text-black`}
    >
      <th
        style={{ wordBreak: "break-all" }}
        className="flex gap-2 items-center"
      >
        <img src={image} width={20} height={20} alt="chad" />

        <p className="font-serifBold text-chad-Heliotrope">{name}</p>
      </th>

      <th
        style={{ wordBreak: "break-all" }}
        className="flex gap-2 items-center"
      >
        <p>{truncateString(initial, 12, 5)}</p>
        <img
          style={{
            transform: "translateY(-2px)",
          }}
          width={16}
          height={16}
          src="/images/modal_profile_address_copy.png"
          onClick={() => {
            copyToClipboard(initial);
          }}
          className="cursor-pointer"
          alt="copy address"
        />
      </th>
      <th style={{ wordBreak: "break-all" }}>
        <span>{time}</span>
      </th>
      <th style={{ wordBreak: "break-all" }}>
        <span>{amount}</span>
      </th>
      <th style={{ wordBreak: "break-all" }}>
        <span>${worth}</span>
      </th>
      <th>
        <span
          style={
            PL > 0
              ? {
                  color: "#4AB538",
                }
              : { color: "#FF3D5F" }
          }
          className="font-serifBold"
        >
          {PL > 0 ? "+" : ""}
          {PL}%
        </span>
      </th>
      <th>
        <span className="text-chad-Heliotrope">{option ? "Buy" : "Sell"}</span>
      </th>
    </tr>
  );
};

export default TableMyHolding;
