function truncateString(str: string, num: number, amount = 16) {
  if (str.length > num) {
    return str.slice(0, amount) + "..." + str.slice(-amount);
  } else {
    return str;
  }
}
function formatNumber(num) {
  return num.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

function copyToClipboard(val: string) {
  navigator.clipboard.writeText(val);
  // Alert the copied text
  alert("Copied");
}

const MAX_CLIENT_NUMB = 999000000000000000;

const chartMetricNumbFormat = (numb: number): string => {
  const fractionLength = 5;
  if (numb > 1000) {
    numb = numb;
    return numb > MAX_CLIENT_NUMB ? ">999Q" : `${formatCompactNumber(numb)}`;
  } else {
    return `${shortenFloat(numb, fractionLength)}`;
  }
};

const formatCompactNumber = (number: any) => {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  return formatter.format(number);
};

const shortenFloat = (numb: any, fractionLimit = 7) => {
  const dict: any = {
    "0": "₀",
    "1": "₁",
    "2": "₂",
    "3": "₃",
    "4": "₄",
    "5": "₅",
    "6": "₆",
    "7": "₇",
    "8": "₈",
    "9": "₉",
    "10": "₁₀",
    "11": "₁₁",
    "12": "₁₂",
    "13": "₁₃",
    "14": "₁₄",
    "15": "₁₅",
    "16": "₁₆",
    "17": "₁₇",
    "18": "₁₈",
    "19": "₁₉",
  };

  if (!numb) {
    return numb;
  }

  numb = typeof numb === "string" ? parseFloat(numb) : numb;
  numb = eToNumber(parseFloat(numb.toFixed(18)).toString());
  let int, fraction;
  [int, fraction] = numb.split(".");

  if (fraction && fraction.length > 2) {
    let res = "";
    fraction.match(/([0-9])\1*/g).forEach((el: any) => {
      let seq_length = el.length;
      if (seq_length > 2) {
        res = res.concat(`${el[0]}${dict[seq_length]}`);
      } else {
        res = res.concat(el);
      }
    });
    fraction = res.slice(0, fractionLimit);
    if (fractionLimit > 0) {
      return `${int}.${fraction}`;
    } else {
      return `${int}`;
    }
  } else {
    return numb;
  }
};

/******************************************************************
 * Converts e-Notation Numbers to Plain Numbers
 ******************************************************************
 * @function eToNumber(number)
 * @version  1.00
 * @param   {e nottation Number} valid Number in exponent format.
 *          pass number as a string for very large 'e' numbers or with large fractions
 *          (none 'e' number returned as is).
 * @return  {string}  a decimal number string.
 * @author  Mohsen Alyafei
 * @date    17 Jan 2020
 * Note: No check is made for NaN or undefined input numbers.
 *
 *****************************************************************/
const eToNumber = (num: any) => {
  let sign = "";
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  (num += "").charAt(0) === "-" && ((num = num.substring(1)), (sign = "-"));
  let arr = num.split(/[e]/gi);
  if (arr.length < 2) return sign + num;
  let dot = ".",
    n = arr[0],
    exp = +arr[1],
    w = (n = n.replace(/^0+/, "")).replace(dot, ""),
    pos = n.split(dot)[1] ? n.indexOf(dot) + exp : w.length + exp,
    L: any = pos - w.length,
    s = "" + BigInt(`${w}`);
  w =
    exp >= 0
      ? L >= 0
        ? s + "0".repeat(L)
        : r()
      : pos <= 0
        ? "0" + dot + "0".repeat(Math.abs(pos)) + s
        : r();
  L = w.split(dot);
  if ((L[0] == 0 && L[1] == 0) || (+w == 0 && +s == 0)) w = 0; //** added 9/10/2021
  return sign + w;
  function r() {
    return w.replace(new RegExp(`^(.{${pos}})(.)`), `$1${dot}$2`);
  }
};

export const checkTimestamp = (ts: any) => {
  if (typeof ts === "number" && ts.toString().length < 13) {
    return ts * 1000;
  }

  return ts;
};

const sleep = (ms) => {
  return new Promise((resolve) => 
      setTimeout(resolve, ms));
}

export { truncateString, formatNumber, copyToClipboard, chartMetricNumbFormat, sleep };
