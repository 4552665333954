import { Outlet } from "react-router-dom"
import Header from "../components/header"

type Props = {}

export default function MainLayout({}: Props) {
  return (
    <div className="page-container">
        <Header />
        <main>
            <Outlet />
        </main>
      </div>  
  )
}