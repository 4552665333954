import React, { useEffect, useRef } from "react";
import {
  ChartingLibraryWidgetOptions,
  ResolutionString,
  widget,
} from "./charting_library";

import { chartMetricNumbFormat } from "../../helpers/helpers";
import datafeed from "./config/datafeed";

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];

  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  container: ChartingLibraryWidgetOptions["container"];
}

function Chart() {
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const defaultProps: Omit<ChartContainerProps, "container"> = {
    symbol: "BTC/USD",
    interval: "1D" as ResolutionString,
    datafeedUrl: "",
    libraryPath: "/charting_library/",
    chartsStorageUrl: "",
    chartsStorageApiVersion: "1.1",
    clientId: "",
    userId: "",
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  //   const datafeedObj = Datafeed(configurationData);

  useEffect(() => {
    const widgetOptions: any = {
      debug: true,
      symbol: defaultProps.symbol as string,
      // BEWARE: no trailing slash is expected in feed URL
      // tslint:disable-next-line:no-any
      // datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(defaultProps.datafeedUrl),
      datafeed,
      interval:
        defaultProps.interval as ChartingLibraryWidgetOptions["interval"],
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      locale: "en",
      disabled_features: [
        "header_symbol_search",
        "header_compare",
        "header_saveload",
      ],
      enabled_features: [
        "study_templates",
        "countdown",
        "create_volume_indicator_by_default",
        "seconds_resolution",
      ],
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: "dark",
      custom_formatters: {
        priceFormatterFactory: () => ({
          format: chartMetricNumbFormat,
        }),
      },
    };

    const tvWidget = new widget(widgetOptions);

    // tvWidget.onChartReady(() => {
    //   tvWidget.headerReady().then(() => {
    //     const button = tvWidget.createButton();
    //     button.setAttribute("title", "Click to show a notification popup");
    //     button.classList.add("apply-common-tooltip");
    //     button.addEventListener("click", () =>
    //       tvWidget.showNoticeDialog({
    //         title: "Notification",
    //         body: "TradingView Charting Library API works correctly",
    //         callback: () => {
    //           console.log("Noticed!");
    //         },
    //       })
    //     );
    //     button.innerHTML = "Check API";
    //   });
    // });

    return () => {
      tvWidget.remove();
    };
  });

  return <div ref={chartContainerRef} className="h-full" />;
}

export default React.memo(Chart);
