import Card from "../Card";
import { ReactComponent as Refresh } from "../svgs/refresh.svg";

type Props = {};

export default function CoinsHeld({}: Props) {
  return (
    <Card header={CustomHeader()}>
      <div className="flex flex-col gap-4">
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
        <Item />
      </div>
    </Card>
  );
}

const CustomHeader = () => (
  <div className="flex flex-row gap-1 items-center">
    <h1 className="uppercase">Coins held</h1>
  </div>
);

const Item = () => (
  <div className="border border-solid border-black bg-[#FCFFDF] p-2 flex flex-row gap-2 shadow-[4px_4px_0px_0px_#96969640]">
    <img
      className="w-[100px] h-[100px]"
      src={
        "https://s3-alpha-sig.figma.com/img/e6ed/4d5e/1308a4ba39738e15f7eb2dc7841c566e?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=DrLE6q5FrBv6qZWJxfRGN4m29QXWOkrvIy-aOiwHMbBnSot0I3ZwjT9424TfArXh6r-d8bI5xlGWTnKoGweRwO2ypvXVV5Y4U9ez2oICeRnmmm7OS~lQ-vWzUy0hONobKmy4etI5Hd6lcke7~sMuDS6id-qbnfR3h0g8nnsjSBU2UhWlBGerH-INyGPEpCLX3bjG54vJutlEwe6urgQlRQBHhlGsWcM-0sL4n8SRf-MrwT-uH54r-QXaYTb-2hDlCHNJv25jv0yZO-S-q2~m934vTnnsZogoNG1qCwQT0vV3-fSG5m6AEUTKUfem1rdvHTSNGVHowpVdM6Gf0pNbIg__"
      }
      alt={"avatar"}
    />

    <div className="flex-1 flex flex-col gap-2">
      <div className="flex flex-row">
        <h1
          className="text-[14px] flex-1 text-center"
          style={{
            fontFamily: "MS Sans Serif Bold",
          }}
        >
          The Daily Whisker - WHISKER
        </h1>
        <Refresh />
      </div>

      <div className="bg-[#FF3D5F] max-h-[31px] pl-[33px] pr-[10px] border border-solid border-[#FF3D5F] flex flex-row items-center text-white gap-1">
        <p className="text-[25px] font-[ThaleahFat] flex-1">732</p>
        <p className="text-[25px] font-[ThaleahFat]">WHISKER</p>
      </div>

      <div className="bg-[#FF3D5F] max-h-[31px] pl-[33px] pr-[10px] border border-solid border-[#FF3D5F] flex flex-row items-center text-white gap-1">
        <p className="text-[25px] font-[ThaleahFat] flex-1">0.2444</p>
        <p className="text-[25px] font-[ThaleahFat]">SOL</p>
      </div>
    </div>
  </div>
);
