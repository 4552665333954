import React from "react";
import { ReactComponent as Close } from "./svgs/close.svg";

type Props = {
  header?: React.ReactNode;
  children?: React.ReactNode;
};

export default function Card({ header, children }: Props) {
  return (
    <div className="p-3 shadow-[8px_8px_0px_0px_#000000] bg-white border-2 border-solid border-[#F7DBD7] text-black">
      <div className="flex flex-row gap-4 items-center mb-2">
        <div
          style={{ fontFamily: "ThaleahFat, sans-serif" }}
          className="flex-1 text-[22px] font-medium"
        >
          {header}
        </div>

        <div
          style={{
            boxShadow:
              "rgb(247, 219, 215) 1px 1px 0px 1px inset, rgb(222, 69, 96) -1px -1px 0px 1px inset",
            border: "2px solid #FAE0E4",
            width: "25px",
            height: "25px",
            background: "#F4C1CA",
          }}
          className="flex justify-center items-center"
        >
          <Close />
        </div>
      </div>
      {children}
    </div>
  );
}
