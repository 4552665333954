// import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "../Card";
import { ReactComponent as Folder } from "../svgs/folder.svg";
import { ReactComponent as Copy } from "../svgs/copy.svg";
// import ButtonGray from "../../Button/ButtonGray";
import { useWallet, useAnchorWallet } from "@solana/wallet-adapter-react";
// import { useCookies } from "react-cookie";
// import { truncateString } from "../../../helpers/helpers";
import { END_POINT } from '../../../constants';
import Cookies from 'js-cookie';
import axios from "axios";
import toast from "react-hot-toast";
import { truncateString } from "../../../helpers/helpers";
if(Cookies.get('token')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
}

type Props = {};

export default function WalletInfoCard({}: Props) {
  const wallet = useAnchorWallet();
  const { disconnect } = useWallet();
  // const [cookies, setCookie, removeCookie] = useCookies();
  // interface Profile {
  //   wallet: string
  // }
  const [myProfile, setMyProfile] = useState<any>();
  useEffect(() => {
    axios.get(END_POINT + '/users/my-profile')
    .then(response => {
      if(response.status === 200 && response.data.data) {
        setMyProfile(response.data.data);
      }
    })
    .catch(error => {
      console.log(error);
    });
  }, []);

  return (
    <Card header={CustomHeader()}>
      <div className="bg-[#FFB5B5] border border-solid border-black p-4">

        {myProfile?.avatar &&
          <Banner
            avatar={myProfile.avatar}
            username={myProfile.username}
            followers={myProfile.followers}
          />
        }
        <Divider />
        {myProfile?.wallet &&
          <PublicAddress
            wallet={wallet}
            address={myProfile.wallet}
          />
        }
      </div>
      <div className="flex flex-row gap-4 justify-center mt-4">
        <Link
          className="btn-gray flex items-center justify-center"
          to={`https://solscan.io/address/${wallet?.publicKey.toBase58()}`}
        >
          View on solscan
        </Link>
        <button
          className="btn-gray flex items-center justify-center"
          onClick={() => {
            disconnect();
            // removeCookie('SolanaSignedIn');
            Cookies.remove("token");
          }}
        >
          Disconnect wallet
        </button>
      </div>
    </Card>
  );
}

const CustomHeader = () => (
  <div className="flex flex-row gap-1 items-center">
    <Folder></Folder>
    <h1 className="uppercase">my Profile</h1>
  </div>
);

const Banner = ({avatar, username, followers}: {avatar: string, username: string, followers: string}) => (
  <div
    style={{
      backgroundImage: `url('/images/profile/bg-anime.png')`,
      backgroundPosition: "center bottom",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }}
    className="flex flex-row items-center gap-2 text-white p-3"
  >
    <img
      src={avatar}
      alt="avatar"
      className="w-[52px] h-[52px] rounded-full"
    />
    <h1
      style={{
        fontFamily: "MS Sans Serif Bold",
      }}
      className="flex-1 text-lg"
    >
      @{username}
    </h1>
    <h1
      style={{
        fontFamily: "MS Sans Serif",
      }}
      className="text-lg"
    >
      {followers.length}&nbsp; followers
    </h1>
  </div>
);

const Divider = () => (
  <div className="flex flex-row gap-4 items-center my-3">
    <div className="flex-1 h-[1px] bg-[#404040]"></div>
    <p className="text-[#404040]">Wallet Address</p>
    <div className="flex-1 h-[1px] bg-[#404040]"></div>
  </div>
);

const PublicAddress = ({
  wallet,
  address
}: {wallet: any,address: string}) => (
  <div
    style={{
      backgroundImage: `url('/images/profile/bg-input.png')`,
      backgroundPosition: "center center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
      height: "40px",
      color: "#fff",
    }}
    className="flex flex-row items-center px-4 gap-4"
  >
    <p
      style={{
        fontFamily: "MS Sans Serif Bold",
      }}
      className="flex-1"
    >
      {address}
    </p>
    <Copy onClick={() => {
      navigator.clipboard.writeText(wallet.publicKey.toBase58());
      toast.success(
        `Copied: ${truncateString(
          wallet.publicKey.toBase58(),
          16,
          5
        )}`
      );
    }} />
  </div>
);
