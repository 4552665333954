import { SOL_VALUES } from "../../../types";

function InputSide({
  value,
  setValue,
}: {
  value?: SOL_VALUES;
  setValue: React.Dispatch<React.SetStateAction<number | undefined>>;
}) {
  return (
    <div className="sidebar-buy text-black">
      <span
        onClick={() => setValue(SOL_VALUES.RESET)}
        className={"text cursor-pointer"}
      >
        reset
      </span>
      <span
        onClick={() => setValue(SOL_VALUES.ONE)}
        className={
          value === SOL_VALUES.ONE
            ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
            : "text cursor-pointer"
        }
      >
        1SOL
      </span>
      <span
        onClick={() => setValue(SOL_VALUES.TWO)}
        className={
          value === SOL_VALUES.TWO
            ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
            : "text cursor-pointer"
        }
      >
        2SOL
      </span>
      <span
        onClick={() => setValue(SOL_VALUES.FIVE)}
        className={
          value === SOL_VALUES.FIVE
            ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
            : "text cursor-pointer"
        }
      >
        5SOL
      </span>
      <span
        onClick={() => setValue(SOL_VALUES.TEN)}
        className={
          value === SOL_VALUES.TEN
            ? "text border-whileI text-white bg-chad-Heliotrope cursor-pointer"
            : "text cursor-pointer"
        }
      >
        10SOL
      </span>
    </div>
  );
}

export default InputSide;
