// import Sidebar from '../components/sidebar';
import { useState, useEffect } from "react";
import { END_POINT } from '../constants';
import Cookies from 'js-cookie';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import { useAnchorWallet } from "@solana/wallet-adapter-react";
if(Cookies.get('token')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
}


function Following () {
  const [following, setFollowing] = useState([]);
  const [suggest, setSuggest] = useState([]);
  const wallet = useAnchorWallet();
  useEffect(() => {
    if(wallet) {
      const address = wallet.publicKey.toBase58();
      axios.get(END_POINT + '/users/'+address)
      .then(response => {
        if(response.status === 200 && response.data.data) {
          setFollowing(response.data.data.following);
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
    axios.get(END_POINT + '/users/suggest/data')
      .then(response => {
        if(response.status === 200 && response.data.data) {
          setSuggest(response.data.data);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }, [wallet]);
  return (
    <>
      <div className="sidebar-box box-shadow">
        <h3 className="sidebar-box-title">
          <img src="./images/icon_crown1.png" alt="crown" className="icon" />
          Following
        </h3>
        <div className="sidebar-box-content2">
          <ul className="list-follwing">
            {
              following.map((item) => 
              <li key={item.id}>
                <a href={'/profile/'+item.wallet}>
                  <div className="left">
                    <img src={item.avatar} alt="token" />
                    <span className="name">{item.username}</span>
                  </div>
                  <div className="right">
                    <span className="follower">{item.followers.length} &nbsp;followers</span>
                    <img  src="./images/icon_star1.png" alt="start" className="icon" />
                  </div>
                </a>
              </li>
            )}
          </ul>
          <h3 className="trade-title white">
            <span>People you may know</span>
          </h3>

          <ul className="list-follwing">
            {
              suggest.map((item) => 
              <li key={item.id}>
                <a href={'/profile/'+item.wallet}>
                  <div className="left">
                    <img src={item.avatar} alt="token" />
                    <span className="name">{item.username}</span>
                  </div>
                  <div className="right">
                    <span className="follower">{item.followers.length} &nbsp;followers</span>
                    <img  src="./images/icon_star2.png" alt="start" className="icon" />
                  </div>
                </a>
              </li>
              )}
          </ul>
          
        </div>
      </div>
    </>
  )
}

function KingHill () {
  return (
    <>
      <div className="sidebar-box box-shadow">
        <h3 className="sidebar-box-title">
          <img src="./images/icon_crown1.png" alt="crown" className="icon" />
          KING OF THE HILL
        </h3>
        <div className="sidebar-box-content">
          <div className="chad-item chad-profile">
            <a href="/detail">
              <figure className="img">
                <img src="./images/image_profile.gif" alt="img" />
              </figure>
              <div className="caption">
                <div className="created-by">
                  <span className="text">Created by</span>
                  <img src="./images/icon_coin.png" alt="" className="icon-coin" />
                  <span className="text">8KEnfj</span>
                </div>
                <h2 className="title">Trans Trump - TRAMP</h2>
                <p className="market-cap">
                  Market cap: <span className="number bold">48.39K</span>
                  <img src="./images/icon_crown2.png" alt="" className="icon" />
                </p>
                <p className="replies">Replies: 9</p>
              </div>
            </a>
          </div>
          <h3 className="trade-title">
            <span>Trade now</span>
          </h3>
          <ul className="list-trade">
            <li className="top-trade">
              <a className="content" href="/detail">
                <img className="icon" src="./images/icon_profile3.png" alt="icon" />
                <span className="name bold">8etVFt</span>
                <span className="text">created</span>
                <span className="name bold">$TRUMPY</span>
                <img className="icon" src="./images/icon_profile2.png" alt="icon" />
                <span className="date">on 05/09/24</span>
              </a>
            </li>
            <li>
              <a className="content" href="/detail">
                <img src="./images/icon_profile4.png" alt="icon" />
                <span className="name bold">mfkjeet</span>
                <span className="text">sold 3.0783 SOL of</span>
                <span className="name bold">NOTTRUMP</span>
                <img src="./images/icon_profile2.png" alt="icon" />
              </a>
            </li>
            <li>
              <a className="content" href="/detail">
                <img src="./images/icon_profile4.png" alt="icon" />
                <span className="name bold">mfkjeet</span>
                <span className="text">sold 3.0783 SOL of</span>
                <span className="name bold">NOTTRUMP</span>
                <img src="./images/icon_profile2.png" alt="icon" />
              </a>
            </li>
            <li>
              <a className="content" href="/detail">
                <img src="./images/icon_profile4.png" alt="icon" />
                <span className="name bold">mfkjeet</span>
                <span className="text">sold 3.0783 SOL of</span>
                <span className="name bold">NOTTRUMP</span>
                <img src="./images/icon_profile2.png" alt="icon" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}




// function Sidebar() {
//   return (
//     <>
    
//     </>
//   )
// }


export default function Home() {
  const [posts, setPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [order, setOrder] = useState('asc');
  // const [isShowPaginate, setIsShowPaginate] = useState(false);
  const getPost:any = (selectedPage) => {
    axios.get(END_POINT + '/posts/' + selectedPage + '/' + order)
      .then(response => {
        if(response.status === 200 && response.data.data.length) {
          setPageCount(response.data.totalPages);
          setPosts(response.data.data);
          setCurrentPage(selectedPage);
        }
      })
      .catch(error => {
        console.log(error);
      });
  }
  useEffect(() => {
    getPost(1);
  }, [order]);

  const handlePageClick = (event) => {
    const selectedPage = parseInt(event.selected) + 1;
    getPost(selectedPage, 'asc');
  };
  const changeOrder = (order) => {
    setOrder(order);
  }

  return (
    <>
      <div className='container mx-auto'>
        <aside className="sidebar">
          <KingHill />
          <Following />
        </aside>
        <div className="main-content box-shadow">
          <h1 className="page-title">
            <img src="./images/icon_list_chad.png" alt="" className="icon" />
            List Chad
          </h1>
          <div className="filter row">
            <div className="search-box">
              <button className="btn-search">
                <img src="./images/icon_search.png" alt="search" />
              </button>
              <input type="text" placeholder='Search' />
            </div>
            <div className="right-filter">
              <label htmlFor="input1" className="label-checkbox">Show animations</label>
              <label className="common-checkbox">
                <input type="checkbox" id="input1" name="" />
                <span className="text"></span>
              </label>
              
              <label htmlFor="input2" className="label-checkbox">Include nsfw:</label>
              <label className="common-checkbox">
                <input type="checkbox" id="input2" name="" />
                <span className="text"></span>
              </label>

              <div className="common-select">
                <select name="" >
                  <option value="">Sort: bump order</option>
                  <option value="">Sort: bump order</option>
                  <option value="">Sort: bump order</option>
                </select>
              </div>
              <div className="common-select">
                <select 
                  name="order"
                  onChange={(e) => changeOrder(e.target.value)}
                >
                  <option value="asc">Order: asc</option>
                  <option value="desc">Order: desc</option>
                </select>
              </div>
            </div>
          </div>
          <ul className="list-chad">
            {
              posts.map((post) => 
              <li key={post.id} className="chad-item">
                <a href={"/detail/" + post.poolId}>
                  <figure className="img">
                    <img src={post.image} alt="img" />
                  </figure>
                  <div className="caption">
                    <div className="created-by">
                      <span className="text">Created by</span>
                      <img src="./images/icon_coin.png" alt="" className="icon-coin" />
                      <span className="text">{post.username}</span>
                    </div>
                    <h2 className="title">{post.name}</h2>
                    <p className="desc">{post.description}</p>
                    <p className="market-cap">Market cap: <span className="bold">48.39K</span></p>
                    <p className="replies">Replies: {post.replies}</p>
                  </div>
                </a>
              </li>
            )}
          </ul>
          <ReactPaginate
            className="pagination"
            breakLabel="..."
            nextLabel="»"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            pageCount={pageCount}
            forcePage = {currentPage - 1}
            previousLabel="«"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </>
  );
}
