import { dummy } from "./dummy";

export async function makeApiRequest(path: string, urlParameters: any = {}) {
  try {
    const query = Object.keys(urlParameters)
      .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
      .join("&");

    const response = await fetch(`${path}?${query}`, { cache: "no-store" });

    return response.json();
  } catch (error: any) {
    throw new Error(`Request error: ${error.status}`);
  }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange: any, fromSymbol: any, toSymbol: any) {
  const short = `${fromSymbol}/${toSymbol}`;
  return {
    short,
    full: `${exchange}:${short}`,
  };
}

// makeApiRequest and generateSymbol implementation
// ...
// Returns all parts of the symbol
export function parseFullSymbol(fullSymbol: string) {
  const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
  if (!match) {
    return null;
  }
  return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}
// fake data
let count = 0;
export const fetchChart = (): Promise<any> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      if (count === 0) {
        count++;
        resolve(dummy);
      } else {
        resolve([]);
      }
      // resolve(dummy)
    }, 1000); // Simulate a 1-second delay
  });
};

export const getTimeZone = () => {
  try {
    let offset = new Date().getTimezoneOffset() / 60;
    return `Etc/GMT${offset > 0 ? "+" : ""}${offset}`;
    //return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    //   logger.error(e);
    console.log(e);
    return "Etc/UTC";
  }
};
