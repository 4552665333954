import { OPTIONS_DETAIL } from "../../../types";
import TableLimitOrders from "../TableLimitOrders/TableLimitOrders";
import TableMyHolding from "../TableMyHolding/TableMyHolding";
import TableThread from "../TableThread/TableThread";
import TableTrades from "../TableTrades/TableTrades";

function Options({
  option,
  onClick,
}: {
  option: OPTIONS_DETAIL;
  onClick: (newO: OPTIONS_DETAIL) => void;
}) {
  return (
    <>
      <div className="detail-trades">
        <div className="detail-trades-menu">
          <div className="detail-trades-button-list max-md:grid max-lg:flex max-blg:grid max-blg:grid-cols-2 max-blg:place-items-center max-blg:w-full">
            <button
              style={
                option === OPTIONS_DETAIL.THREAD
                  ? {
                      backgroundImage:
                        "url('/images/detail/bg-active-option.png')",
                      color: "#000",
                    }
                  : { backgroundImage: "url('/images/bg_button_grey.png')" }
              }
              className="button-thread tabs bg-size100 bg-mid-center"
              onClick={() => onClick(OPTIONS_DETAIL.THREAD)}
            >
              Thread
            </button>

            <button
              style={
                option === OPTIONS_DETAIL.TRADES
                  ? {
                      backgroundImage:
                        "url('/images/detail/bg-active-option.png')",
                      color: "#000",
                    }
                  : { backgroundImage: "url('/images/bg_button_grey.png')" }
              }
              className="button-thread tabs bg-size100 bg-mid-center"
              onClick={() => onClick(OPTIONS_DETAIL.TRADES)}
            >
              TRADES
            </button>
            <button
              style={
                option === OPTIONS_DETAIL.MY_HODING
                  ? {
                      backgroundImage:
                        "url('/images/detail/bg-active-option.png')",
                      color: "#000",
                    }
                  : { backgroundImage: "url('/images/bg_button_grey.png')" }
              }
              className="button-thread tabs bg-size100 bg-mid-center"
              onClick={() => onClick(OPTIONS_DETAIL.MY_HODING)}
            >
              MY HOLDING
            </button>
            <button
              style={
                option === OPTIONS_DETAIL.LIMIT_ORDERS
                  ? {
                      backgroundImage:
                        "url('/images/detail/bg-active-option.png')",
                      color: "#000",
                    }
                  : { backgroundImage: "url('/images/bg_button_grey.png')" }
              }
              className="button-thread tabs bg-size100 bg-mid-center"
              onClick={() => onClick(OPTIONS_DETAIL.LIMIT_ORDERS)}
            >
              LIMIT ORDERS
            </button>
          </div>
        </div>
        <div className="detail-trades-list">
          <div className="max-h-[360px] view-table overflow-y-auto max-sm:overflow-x-hidden">
            {option === OPTIONS_DETAIL.THREAD ? <TableThread /> : <></>}
            {option === OPTIONS_DETAIL.TRADES ? <TableTrades /> : <></>}
            {option === OPTIONS_DETAIL.MY_HODING ? <TableMyHolding /> : <></>}
            {option === OPTIONS_DETAIL.LIMIT_ORDERS ? (
              <TableLimitOrders />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Options;
