import { useState } from "react";

interface ObjectSwap {
  firstVal: string;
  secondVal: string;
  firstToken: string;
  secondToken: string;
  firstIcon: string;
  secondIcon: string;
}

function LimitTrade() {
  const [isBuy, setIsBuy] = useState<boolean>(true);
  const [val, setVal] = useState<ObjectSwap>({
    firstIcon: "/images/icon_profile3.png",
    firstToken: "BBBY",
    firstVal: "",
    secondIcon: "/images/sol_icon.png",
    secondToken: "SOL",
    secondVal: "",
  });
  return (
    <div className="sidebar-box-content-v2">
      <div className="grid grid-cols-2">
        <button
          className={`text-2xl xl:text-[29px] ${
            isBuy ? "text-[#AD20EF]" : "text-[#404040]"
          }`}
          style={{
            fontFamily: "ThaleahFat",
            borderBottom: "2px solid #000",
            borderRight: "2px solid #000",
          }}
          onClick={() => setIsBuy(true)}
        >
          BUY LIMIT
        </button>
        <button
          className={`text-2xl xl:text-[29px] ${
            !isBuy ? "text-[#AD20EF]" : "text-[#404040]"
          }`}
          style={{
            fontFamily: "ThaleahFat",
            borderBottom: "2px solid #000",
          }}
          onClick={() => setIsBuy(false)}
        >
          SELL LIMIT
        </button>
      </div>
      <div className="relative">
        <img
          onClick={() => {
            setVal(() => {
              const cVal = {
                firstIcon: val.secondIcon,
                firstToken: val.secondToken,
                firstVal: val.secondVal,
                secondIcon: val.firstIcon,
                secondToken: val.firstToken,
                secondVal: val.firstVal,
              };
              return cVal;
            });
          }}
          style={{
            transform: "translate(-50%,-50%)",
            zIndex: 2,
          }}
          width={30}
          height={30}
          src="/images/detail/icon-swap.png"
          alt=""
          className="absolute top-[50%] left-[50%] cursor-pointer"
        />
        <Input
          val={val.firstVal}
          setVal={(newVal) =>
            setVal(() => {
              const cVal = { ...val, firstVal: newVal };
              return cVal;
            })
          }
          token={val.firstToken}
          icon={val.firstIcon}
        />
        <Input
          val={val.secondVal}
          setVal={(newVal) =>
            setVal(() => {
              const cVal = { ...val, secondVal: newVal };
              return cVal;
            })
          }
          token={val.secondToken}
          icon={val.secondIcon}
        />
      </div>
      <div>
        <InputForBuy />
      </div>
      <div className="sidebar-button -mt-4">
        <button
          style={{
            backgroundImage: "url('/images/bg_button_common.png')",
          }}
          className="font-serifBold text-black bg-size100 bg-mid-center"
        >
          Place order
        </button>
      </div>
    </div>
  );
}

const InputForBuy = () => {
  const [value, setValue] = useState();
  return (
    <div>
      <div className="flex justify-between text-base">
        <span className="text-black">Buy DEXC at rate</span>
        <span className="text-[#AD20EF]">Use Market</span>
      </div>
      <Input val={value} setVal={(newVal) => setValue(newVal)} />
    </div>
  );
};

const Input = ({
  icon = "/images/sol_icon.png",
  token = "SOL",
  val,
  setVal,
}: {
  icon?: string;
  token?: string;
  val?: string;
  setVal: any;
}) => {
  return (
    <div className="sidebar-box-input sidebar-box-input-v2 text-base font-serifBold text-black">
      <input
        style={{
          backgroundImage: "url('/images/bg_input.png')",
        }}
        className="placeholder:text-black bg-mid-center outline-none bg-size100 bg-no-repeat bg-transparent"
        type="number"
        placeholder="0"
        value={val}
        onChange={(e) => setVal(e.target.value)}
      />
      <span>
        {token}
        <div className="w-5 h-5">
          <img
            // src="/images/Solana-Logo.png"
            src={icon}
            alt="crown"
            className="icon rounded-full"
          />
        </div>
      </span>
    </div>
  );
};

export default LimitTrade;
