import { fetchChart } from "./helpers";
// import { subscribeOnStream, unsubscribeFromStream } from './streaming'
// import { checkTimestamp } from '@/shared/helper'

const supportedResolutions = [
  "1S",
  "1",
  "5",
  "15",
  "30",
  "60",
  "240",
  "360",
  "720",
  "1440",
];

const lastBarsCache = new Map();

const configurationData = {
  // Represents the resolutions for bars supported by your datafeed
  supported_resolutions: supportedResolutions,
  // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
  exchanges: [{ value: "SOL/USD", name: "SOL/USD", desc: "SOL" }],
  // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
  symbols_types: [{ name: "crypto", value: "crypto" }],
  // supports_marks: true,
  // supports_timescale_marks: true
};

export default {
  onReady: (callback: any) => {
    console.log("[onReady]: Method call");
    setTimeout(() => callback(configurationData), 0);
  },
  //   searchSymbols: async (
  //       userInput: string,
  //       exchange: string,
  //       _: string,
  //       onResultReadyCallback: (symbols: any[]) => void
  //   ) => {
  //       console.log('[searchSymbols]: Method call')
  //   const symbols = await getAllSymbols()
  //   const newSymbols = symbols.filter((symbol: any) => {
  //       const isExchangeValid = exchange === '' || symbol.exchange === exchange
  //       const isFullSymbolContainsInput = symbol.ticker.toLowerCase().indexOf(userInput.toLowerCase()) !== -1
  //       return isExchangeValid && isFullSymbolContainsInput
  //   })
  //   onResultReadyCallback(newSymbols)
  //   },
  resolveSymbol: async (
    symbolName: string,
    onSymbolResolvedCallback: (symbolInfo: any) => void,
    onResolveErrorCallback: (error: any) => void,
    _?: any
  ) => {
    console.log("[resolveSymbol]: Method call", symbolName);
    // const symbols = await getAllSymbols()
    // const symbolItem = symbols.find(({ ticker }: any) => ticker === symbolName)
    const symbolItem = configurationData.exchanges[0];
    if (!symbolItem) {
      console.log("[resolveSymbol]: Cannot resolve symbol", symbolName);
      onResolveErrorCallback("Cannot resolve symbol");
      return;
    }
    // Symbol information object
    const symbolInfo = {
      ticker: symbolItem.name || symbolItem.value,
      name: symbolItem.name || symbolItem.value,
      description: symbolItem.desc,
      type: symbolItem.value,
      session: "24x7",
      // timezone: getTimeZone(),
      exchange: "",
      minmov: 1,
      pricescale: Math.pow(10, 14),
      has_intraday: true,
      intraday_multipliers: [
        "1S",
        "1",
        "5",
        "15",
        "30",
        "60",
        "240",
        "360",
        "720",
        "1440",
      ],
      volume_precision: 8,
      has_seconds: true,
      seconds_multipliers: [1],
      data_status: "streaming",
      supported_resolutions: configurationData.supported_resolutions,
      supports_marks: true,
      visible_plots_set: "ohlcv",
    };
    console.log("[resolveSymbol]: Symbol resolved", symbolName);
    // onSymbolResolvedCallback(symbolInfo)
    setTimeout(() => onSymbolResolvedCallback(symbolInfo), 0);
  },
  getBars: async (
    symbolInfo: any,
    _: string,
    periodParams: any,
    onHistoryCallback: any,
    onErrorCallback: (error: any) => void
  ) => {
    // const { from, to, firstDataRequest, countBack } = periodParams
    const { firstDataRequest } = periodParams;

    console.log("[getBars]: Method call", symbolInfo);
    // const parsedSymbol: any = parseFullSymbol(symbolInfo.ticker)
    // const urlParameters: any = {
    //     e: parsedSymbol.exchange,
    //     fsym: parsedSymbol.fromSymbol,
    //     tsym: parsedSymbol.toSymbol,
    //     toTs: to,
    //     limit: 2000
    // }
    // const query = Object.keys(urlParameters)
    //     .map((name) => `${name}=${encodeURIComponent(urlParameters[name])}`)
    //     .join('&')
    try {
      // const data = await makeApiRequest(`data/histoday?${query}`)
      //   testApi();
      const rawData = await fetchChart();
      // let hasFirstPoint;
      // if (isNaN(firstEventTimestamp) || firstEventTimestamp === 0 ){
      //   hasFirstPoint = true;
      // }else{
      //   hasFirstPoint = query.from <= firstEventTimestamp;
      // }

      // debugger
      // const data: any = { Data: dummy }
      // fake data
      // if ((data.Response && data.Response === 'Error') || data.length === 0) {
      //     // "noData" should be set if there is no data in the requested period
      //     onHistoryCallback([], { noData: true })
      //     return
      // }
      let bars: any = [];
      if (rawData && rawData.length) {
        bars = rawData
          .map((item: any) => {
            return {
              time: item.timestamp,
              low: parseFloat(item.l),
              high: parseFloat(item.h),
              open: parseFloat(item.o),
              close: parseFloat(item.c),
              volume: item.volume,
            };
          })
          .sort((a: any, b: any) => a.time - b.time);

        bars = bars.map((bar: any, indx: number) => {
          if (indx === 0) return bar;

          return {
            ...bar,
            open: bars[indx - 1].close,
          };
        });
      }

      if (firstDataRequest && bars.length > 0) {
        lastBarsCache.set(symbolInfo.full_name, {
          ...bars[bars.length - 1],
        });
      }
      console.log(`[getBars]: returned ${bars.length} bar(s)`);
      console.log(bars);
      // debugger
      onHistoryCallback(bars, { noData: bars.length === 0 });
    } catch (error) {
      console.log("[getBars]: Get error", error);
      onErrorCallback(error);
    }
  },
  subscribeBars: (
    symbolInfo: any,
    resolution: string,
    onRealtimeCallback: (bar: any) => void,
    subscriberUID: string,
    _: () => void
  ) => {
    console.log(
      "[subscribeBars]: Method call with subscriberUID:",
      subscriberUID
    );
    // subscribeOnStream(
    //   symbolInfo,
    //   resolution,
    //   onRealtimeCallback,
    //   subscriberUID,
    //   onResetCacheNeededCallback,
    //   lastBarsCache.get(`${symbolInfo.exchange}:${symbolInfo.name}`)
    // );
  },
  unsubscribeBars: (subscriberUID: string) => {
    console.log(
      "[unsubscribeBars]: Method call with subscriberUID:",
      subscriberUID
    );
    // unsubscribeFromStream(subscriberUID);
  },
};
