import { useState } from "react";

import axios from "axios";

import "../scss/detail.scss";
import Chart from "../components/chart/Chart";
import { OPTIONS_DETAIL } from "../types";
import TradeComponent from "../components/details/TradeComponent/TradeComponent";
import Info from "../components/details/Info/Info";
import HolderDistribution from "../components/details/HolderDistribution/HolderDistribution";
import Options from "../components/details/Options/Options";

import Cookies from "js-cookie";

if (Cookies.get("token")) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${Cookies.get(
    "token"
  )}`;
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded";
}

enum ACTIVE {
  CHART = 1,
  TRADE = 2,
}

export default function Detail() {
  const [optionDetail, setOptionDetail] = useState<OPTIONS_DETAIL>(
    OPTIONS_DETAIL.THREAD
  );
  const [optionChart, setOptionChart] = useState<ACTIVE>(ACTIVE.CHART);
  return (
    <div className="container mx-auto">
      <div
        className={`main-content box-shadow detail-main ${
          optionChart === ACTIVE.CHART ? "" : "max-lg:hidden"
        }`}
      >
        <TitleChart />
        <HeaderChart />
        <div className="detail-container">
          <div className="detail-container-chart">
            <figure className="img">
              <Chart />
            </figure>
            <Options
              option={optionDetail}
              onClick={(newO) => setOptionDetail(newO)}
            />
          </div>
        </div>
      </div>
      <aside
        className={`sidebar siderbar-right !min-w-[300px] ${
          optionChart === ACTIVE.TRADE ? "" : "max-lg:hidden"
        }`}
      >
        <TradeComponent />
        <Info />
        <HolderDistribution />
      </aside>
      <OptionMobile active={optionChart} onClick={setOptionChart} />
    </div>
  );
}

const OptionMobile = ({
  active,
  onClick,
}: {
  active: ACTIVE;
  onClick: React.Dispatch<React.SetStateAction<ACTIVE>>;
}) => {
  return (
    <div
      style={{
        left: "50%",
        transform: "translateX(-50%)",
      }}
      className="lg:hidden box-shadow-2 fixed max-sm:bottom-10 bottom-16 bg-white w-[80%] max-w-[400px] grid grid-cols-2 px-4 py-2 gap-3"
    >
      <button
        style={
          active === ACTIVE.CHART
            ? {
                backgroundImage: "url('/images/detail/bg-active-option.png')",
                color: "#000",
              }
            : { backgroundImage: "url('/images/bg_button_grey.png')" }
        }
        className="button-thread tabs bg-size100 bg-mid-center p-2 "
        onClick={() => onClick(ACTIVE.CHART)}
      >
        Chart
      </button>
      <button
        style={
          active === ACTIVE.TRADE
            ? {
                backgroundImage: "url('/images/detail/bg-active-option.png')",
                color: "#000",
              }
            : { backgroundImage: "url('/images/bg_button_grey.png')" }
        }
        className="button-thread tabs bg-size100 bg-mid-center "
        onClick={() => onClick(ACTIVE.TRADE)}
      >
        Buy/Sell
      </button>
    </div>
  );
};

const TitleChart = () => {
  return (
    <div className="breadcrumbs">
      <img src="/images/Frame 27.png" alt="" className="icon-coin" />
      <span className="text">Chart</span>
    </div>
  );
};

const HeaderChart = () => {
  return (
    <div className="detatil-menu text-black">
      <div className="detatil-tab-menu">
        <div className="detatil-tab-text">
          <span className="text">Bed Bath Beyond - BBBY</span>
        </div>
        <div className="detatil-tab-text">
          <span className="text">Market cap: $4.579,911</span>
        </div>
        <div className="detatil-tab-text">
          <span className="text">Virtual liquidity: $9.798</span>
        </div>
      </div>
      <div className="detatil-info">
        <div className="detatil-info-list">
          <div className="detatil-info-text">
            <span className="text">Created by</span>
          </div>
          <div className="detatil-info-text">
            <img src="/images/info.png" alt="img" />
          </div>
          <div className="detatil-info-text">
            <span className="text">8KEnfj</span>
          </div>
        </div>
      </div>
    </div>
  );
};
