import { useState } from "react";
import { BUTTON_OPTIONS } from "../../../types";
import SellDetail from "../SellDetail/SellDetail";
import InputSide from "../InputSide/InputSide";
import BuyTrade from "../BuyTrade/BuyTrade";
import LimitTrade from "../LimitTrade/LimitTrade";

export default function TradeComponent({}: {}) {
  const [value, setValue] = useState<number>();
  const [option, setOption] = useState<BUTTON_OPTIONS>(BUTTON_OPTIONS.BUY);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const handleSwitchOption = (newOption) => {
    if (newOption != option) setOption(newOption);
  };
  return (
    <div className="sidebar-box box-shadow">
      <h3 className="sidebar-box-title text-black">
        <img src="/images/icon_trade.png" alt="crown" className="icon" /> TRADE
      </h3>
      <div className="detail-trades-button-list">
        <button
          style={{
            backgroundImage: `url('${
              option === BUTTON_OPTIONS.BUY
                ? "/images/detail/bg-buy.png"
                : "/images/bg_button_grey.png"
            }')`,
          }}
          className="bg-mid-center bg-size100"
          onClick={() => {
            handleSwitchOption(BUTTON_OPTIONS.BUY);
          }}
        >
          Buy
        </button>
        <button
          style={{
            backgroundImage: `url('${
              option === BUTTON_OPTIONS.SELL
                ? "/images/detail/bg-sell.png"
                : "/images/bg_button_grey.png"
            }')`,
          }}
          className="bg-mid-center bg-size100"
          onClick={() => {
            handleSwitchOption(BUTTON_OPTIONS.SELL);
          }}
        >
          Sell
        </button>
        {/* <button
          style={{
            backgroundImage: `url('${
              option === BUTTON_OPTIONS.LIMIT
                ? "/images/detail/bg-limit.png"
                : "/images/bg_button_grey.png"
            }')`,
          }}
          className="bg-mid-center bg-size100"
          onClick={() => {
            handleSwitchOption(BUTTON_OPTIONS.LIMIT);
          }}
        >
          Limit
        </button> */}
      </div>

      {option !== BUTTON_OPTIONS.LIMIT ? (
        <div className="sidebar-box-content">
          {/* {option === BUTTON_OPTIONS.SELL ? (
            <SellDetail invested={0} PL={0} remaining={0.00082115} sold={0} />
          ) : (
            <></>
          )} */}

          <div>
            <div className="sidebar-box-des">
              <span>Swich to BBBY</span>
              {/* <span>Set max slippage</span> */}
            </div>
            <div className="sidebar-box-input text-base font-serifBold text-black">
              <input
                style={{
                  backgroundImage: "url('/images/bg_input.png')",
                }}
                className="placeholder:text-black bg-mid-center outline-none bg-size100 bg-no-repeat bg-transparent"
                type="number"
                value={value}
                onChange={(e) => {
                  if (Number(e.target.value) < 0) {
                    return;
                  }
                  setValue(Number(e.target.value));
                }}
                placeholder="0"
              />
              <span>
                SOL
                <div>
                  <img
                    src="/images/Solana-Logo.png"
                    alt="crown"
                    className="icon"
                    width={20}
                    height={20}
                  />
                </div>
              </span>
            </div>
          </div>
          <InputSide value={value} setValue={setValue} />
          <div
            onClick={() => {
              setShowOptions(() => !showOptions);
            }}
            className="flex gap-2 items-center justify-center text-chad-Heliotrope mt-4 cursor-pointer"
          >
            <p> Show more option</p>
            <div>
              <img
                style={
                  showOptions
                    ? {
                        rotate: "180deg",
                      }
                    : {}
                }
                width={16}
                height={16}
                src="/images/detail/icon-down.png"
                alt=""
              />
            </div>
          </div>
          <div
            className={`flex-col gap-4 text-black ${
              showOptions ? "flex" : "hidden"
            }`}
          >
            {option === BUTTON_OPTIONS.BUY ? <BuyTrade /> : <></>}
            {option === BUTTON_OPTIONS.SELL ? <BuyTrade /> : <></>}
          </div>
          <div className="sidebar-button">
            <button
              style={{
                backgroundImage: "url('/images/bg_button_common.png')",
              }}
              className="font-serifBold text-black bg-size100 bg-mid-center"
            >
              {option === BUTTON_OPTIONS.BUY ? "Buy" : "Sell"}
            </button>
          </div>
        </div>
      ) : (
        <LimitTrade></LimitTrade>
      )}
    </div>
  );
}
