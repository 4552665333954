import Card from "../Card";

type Props = {};

export default function Notifications({}: Props) {
  return (
    <Card header={CustomHeader()}>
      <div className="grid place-items-center min-h-[200px]">
        <div className="flex flex-col gap-4 items-center">
          <img
            width={60}
            height={60}
            src="/images/profile/bell.png"
            alt="don't have any notification"
          />
          <p className="text-[#686868] font-serifBold">No notification</p>
        </div>
      </div>
    </Card>
  );
}

const CustomHeader = () => (
  <div className="flex flex-row gap-1 items-center">
    <h1 className="uppercase">Notifications</h1>
  </div>
);
